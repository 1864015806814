import {FC, useState} from "react";
import styles from '../PackageCheckPage.module.scss';
import {Input, InputProps} from "antd";
import {SearchOutlined} from "@ant-design/icons";

interface Props extends InputProps {
  setPackageId: (id: string) => void;
  containerClassName?: string;
}

export const PSearch: FC<Props> = ({setPackageId, containerClassName, ...props}) => {
  const [search, setSearch] = useState<string>('')
  return (
    <div className={containerClassName || styles.searchContainer}>
      <Input
        onChange={(e) => setSearch(e.currentTarget.value)}
        onPressEnter={(e) => setPackageId(e.currentTarget.value)}
        className={styles.searchInput}
        placeholder="Поиск по трек-номеру" suffix={<SearchOutlined onClick={() => setPackageId(search)}/>}
        {...props}
      />
    </div>
  )
}