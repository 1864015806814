import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {DeliveryCompanyItem} from "@typings/models/orders";

// Define a type for the slice state
interface State {
  data: DeliveryCompanyItem[] | null;
}

// Define the initial state using that type
const initialState: State = {
  data: null,
}

export const deliveryTypes = createSlice({
  name: 'deliveryTypes',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setDeliveryTypes: (state, action: PayloadAction<State>) => {
      state.data = action.payload.data;
    },
  }
})

export const {
  setDeliveryTypes,
} = deliveryTypes.actions

export default deliveryTypes.reducer