import {getMarketPVZ, loginUser} from "@api";
import {DATE_FORMAT, PVZ_CODE} from "@constants";
import {showError} from "@utils/show_error";
import dayjs from "dayjs";
import {login} from "@utils/auth/auth";
import {LoginFormValues} from "@components/Login/Login";

export const finishLogin = (values: LoginFormValues) => {
  getMarketPVZ({
    params: {
      CompanyCode: values.username.replace(/\D/g, ''),
    }
  }).then((response) => {
      let marketPvz: number | null = null;
      if (response.data.length && response.data[0].id) {
        marketPvz = response.data[0].id;
      }
      loginUser({
        params: {
          userName: values.username.replace(/\D/g, ''),
          password: values.password,
          marketPvz: marketPvz || PVZ_CODE,
          companyCode: values.username.replace(/\D/g, ''),
          clientVersion: 'MOBILE',
        },
      }).then(({data}) => {
        if (data.result === -1 && !data.token)
          showError('Неверный логин или пароль')
        else {
          localStorage.setItem('marketPvz', String(marketPvz || PVZ_CODE))
          // !!! бэк возвращает неверную дату
          localStorage.setItem('dateReg', dayjs(data.dateReg).format(DATE_FORMAT))
          login({accessToken: data.token})
          window.location.href = '/request-list';
        }
      }).catch(
        (error) => showError(error?.response?.data?.error || error.message)
      )
    }
  )
};