import React, {FC} from "react";
import clsx from "clsx";
import styles from "./MapPage.module.scss";
import {Input} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import CRMap from "@pages/CreateRequestPage/components/CRMap";
import {DeliveryTypeSelect} from "@components/DeliveryTypeSelect";
import {getProvider} from "@pages/CreateRequestPage/utils/serialize";
import {CreateRequestFormData} from "@typings/pages/createRequestTypes";
import {RootState, useAppSelector} from "@store";

export const MapPage: FC = () => {
  const {data: deliveryTypesList} = useAppSelector((state: RootState) => state.deliveryTypes);
  const [mapOptions, setMapOptions] = React.useState({
    searchValue: '',
    providerId: 0
  });

  return (
    <div className={clsx(styles.formContainer)}>
      <div className={clsx(styles.island, styles.mapContainer)}>
        <DeliveryTypeSelect
          className={styles.deliveryTypeSelect}
          placeholder='Выберите ТК'
          onChange={(value) => {
            const provider = getProvider({
              delivery_to_type: false,
              delivery_type: value
            } as unknown as CreateRequestFormData, deliveryTypesList)
            setMapOptions((prev) => ({...prev, providerId: provider?.provID || 0}))
          }}
        />
        <Input
          onPressEnter={(e) => setMapOptions((prev) => ({...prev, searchValue: e.currentTarget.value}))}
          suffix={<SearchOutlined/>}
          placeholder={'Поиск'}
        />
        <CRMap search={mapOptions.searchValue} providerId={mapOptions.providerId} className={styles.map}/>
      </div>
    </div>
  )
}