import {FC} from "react";
import {Space} from "antd";
import styles from './RequestsPage.module.scss'
import {CreateRequestForm} from "@pages/CreateRequestPage/components/CreateRequestForm";
import {CreateRequestProvider} from "@pages/CreateRequestPage/hooks/useCRequest";

export const CreateRequestPage: FC = () => {
  return (
    <CreateRequestProvider>
      <Space direction='vertical' className={styles.requestPageContainer}>
        <CreateRequestForm />
      </Space>
    </CreateRequestProvider>
  )
}