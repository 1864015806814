import React, { useEffect } from 'react';
import { AutoComplete } from 'antd';
import { BaseOptionType, SelectProps } from 'rc-select/lib/Select';
import Axios from 'axios';
import { DADATA_API_KEY } from '@constants';
import { useAppSelector } from '@store';
import { getOnlyStreet } from '@pages/CreateRequestPage/components/utils';

export interface DCAValue {
  value: string;
  unrestricted_value: string;
  data: {
    code: string;
    alfa2: string;
    house?: string;
  };
}

export interface DAValue extends Omit<DCAValue, 'data'> {
  value: string;
  unrestricted_value: string;
  data: {
    postal_code: string;
    country: string;
    country_iso_code: string;
    federal_district: string;
    region_fias_id: string;
    region_kladr_id: string;
    region_iso_code: string;
    region_with_type: string;
    region_type: string;
    region_type_full: string;
    region: string;
    area_fias_id: null | string;
    area_kladr_id: null | string;
    area_with_type: null | string;
    area_type: null | string;
    area_type_full: null | string;
    area: null | string;
    city_fias_id: string;
    city_kladr_id: string;
    city_with_type: string;
    city_type: string;
    city_type_full: string;
    city: string;
    city_area: null | string;
    city_district_fias_id: null | string;
    city_district_kladr_id: null | string;
    city_district_with_type: null | string;
    city_district_type: null | string;
    city_district_type_full: null | string;
    city_district: null | string;
    settlement_fias_id: null | string;
    settlement_kladr_id: null | string;
    settlement_with_type: null | string;
    settlement_type: null | string;
    settlement_type_full: null | string;
    settlement: null | string;
    street_fias_id: string;
    street_kladr_id: string;
    street_with_type: string;
    street_type: string;
    street_type_full: string;
    street: string;
    stead_fias_id: null | string;
    stead_cadnum: null | string;
    stead_type: null | string;
    stead_type_full: null | string;
    stead: null | string;
    house_fias_id: null | string;
    house_kladr_id: null | string;
    house_cadnum: null | string;
    house_flat_count: null | string;
    house_type: string;
    house_type_full: string;
    house: string;
    block_type: null | string;
    block_type_full: null | string;
    block: null | string;
    entrance: null | string;
    floor: null | string;
    flat_fias_id: null | string;
    flat_cadnum: null | string;
    flat_type: null | string;
    flat_type_full: null | string;
    flat: null | string;
    flat_area: null | string;
    square_meter_price: null | string;
    flat_price: null | string;
    room_fias_id: null | string;
    room_cadnum: null | string;
    room_type: null | string;
    room_type_full: null | string;
    room: null | string;
    postal_box: null | string;
    fias_id: string;
    fias_code: null | string;
    fias_level: string;
    fias_actuality_state: string;
    kladr_id: string;
    geoname_id: string;
    capital_marker: string;
    okato: string;
    oktmo: string;
    tax_office: string;
    tax_office_legal: string;
    timezone: null | string;
    geo_lat: string;
    geo_lon: string;
    beltway_hit: null | string;
    beltway_distance: null | string;
    metro: null | string;
    divisions: null | string;
    qc_geo: string;
    qc_complete: null | string;
    qc_house: null | string;
    history_values: null | string;
    unparsed_parts: null | string;
    source: null | string;
    qc: null | string;
  };
}

export interface DCAOptionType extends BaseOptionType {
  label?: React.ReactNode;
  value?: string;
  fullValue: DCAValue;
}

export interface DAVOptionType extends BaseOptionType {
  label?: React.ReactNode;
  value?: string;
  fullValue: DAValue;
}

interface Props extends Omit<SelectProps, 'onChange'> {
  onChange: (value: string, option: DCAOptionType) => void;
  url: string;
  forwardValue?: string;
  forPvz?: boolean;
  autoComplete?: string;
  prefix?: string;
  getLabel?: (item: DAVOptionType['fullValue']) => string;
  onFocus?: () => void;
}

interface Has {
  text?: string;
  text2: string | null;
  showAll?: boolean;
}

const has = ({ text, text2, showAll }: Has) => (showAll || text?.includes(text2 || '')) && text2;

interface PreValue {
  item: DAVOptionType;
  text?: string;
  withStreet?: boolean;
  showAll?: boolean;
}

const getPreValue = ({ item, text, withStreet, showAll }: PreValue) =>
  [
    has({
      text,
      text2: item?.fullValue?.data?.region_with_type,
      showAll,
    }),
    has({
      text,
      text2: item?.fullValue?.data?.city_with_type,
      showAll,
    }),
    has({
      text,
      text2: item?.fullValue?.data?.area_with_type,
      showAll,
    }),
    (showAll || withStreet) &&
      has({
        text,
        text2: getOnlyStreet(item),
        showAll,
      }),
  ]
    .reduce((prev: (string | null | undefined | false)[], item) => {
      if (prev.includes(item)) return prev;

      return [...prev, item];
    }, [])
    .filter(i => i)
    .join(', ');

export const DaDataAnyAutoComplete: React.FC<Props> = ({
  url,
  prefix,
  onFocus,
  forPvz,
  getLabel,
  forwardValue,
  onBlur,
  ...props
}) => {
  const [options, setOptions] = React.useState<DCAOptionType[]>([]);
  const [pvzStreetList, setPvzStreetList] = React.useState<DCAOptionType[]>([]);
  const { data: availablePVZ } = useAppSelector(state => state.availablePVZ);

  const fetchData = (value: string) => {
    const fetchUrl = url.includes('https://')
      ? url
      : `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/${url}`;
    return Axios(fetchUrl, {
      method: 'POST',
      headers: {
        Authorization: `Token ${DADATA_API_KEY}`,
      },
      data: {
        query: (forwardValue ? `${forwardValue} ` : '') + (!value ? prefix : value),
      },
    });
  };

  const filterOptionsPVZ = (pvzStreetList: DCAOptionType[], value: string) => {
    return pvzStreetList
      .filter(item =>
        getPreValue({
          item: item as any,
          showAll: true,
        })
          .toLowerCase()
          .includes((forwardValue || '').toLowerCase()),
      )
      .filter(item => item.value?.toLowerCase().includes(value.toLowerCase()));
  };

  const filterOptions = (suggestions: any) => {
    return suggestions
      .map((item: any) => ({
        label: !forwardValue
          ? getLabel?.(item) ?? item.value
          : item.value.replace(`${forwardValue}, `, '').replace(forwardValue, ''),
        fullValue: item,
        value: !forwardValue ? item.value : item.value.replace(`${forwardValue}, `, '').replace(forwardValue, ''),
      }))
      .filter((item: any) => item.label);
  };

  const handleSearch = (value: string) => {
    if (forPvz && pvzStreetList.length) {
      setOptions(filterOptionsPVZ(pvzStreetList, value));
      return;
    }
    if (forPvz) return;

    fetchData(value).then(response => {
      if (response.data.suggestions) {
        setOptions(filterOptions(response.data.suggestions));
      }
    });
  };

  useEffect(() => {
    if (!forPvz && (forPvz || !forwardValue)) {
      setOptions([]);
    }
  }, [availablePVZ, forPvz, forwardValue]);

  useEffect(() => {
    if (forPvz && availablePVZ?.length) {
      setPvzStreetList(
        availablePVZ
          .map(item => {
            const value = `${item.formAddress.street_with_type || item.formAddress.settlement_with_type}, ${
              item.formAddress.house_type
            } ${item.formAddress.house}`;

            return {
              pvzCode: item.code,
              label: value,
              value: value,
              fullValue: {
                value: value,
                unrestricted_value: value,
                data: {
                  ...(item.formAddress as unknown as DCAOptionType['fullValue']['data']),
                  geo_lat: item.lat,
                  geo_lon: item.lon,
                },
              },
            };
          })
          .filter((item, index, self) => self.findIndex(t => t.value === item.value) === index),
      );
    }
  }, [availablePVZ, forPvz]);

  const onFocusHandler = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (onFocus) onFocus();

    const value = e?.target?.value || '';

    handleSearch(value);
  };

  return (
    <AutoComplete<DCAOptionType>
      onFocus={onFocusHandler}
      onSearch={handleSearch}
      options={options}
      onBlur={onBlur}
      {...(props as any)}
    />
  );
};
