import {FC} from "react";
import clsx from "clsx";
import styles from "./TextPage.module.scss";

export const TextPage: FC = () => {
  return (
    <div className={clsx(styles.formContainer)}>
      <div className={clsx(styles.island, styles.contentContainer)}>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut aliquet metus bibendum turpis volutpat, porttitor
          sagittis lorem auctor. Nullam tincidunt non enim nec egestas. Mauris blandit in risus sit amet condimentum.
          Cras
          facilisis risus vitae est sodales posuere. Aliquam bibendum nibh ante, sed sodales eros gravida eget.
          Phasellus
          ut dignissim nibh. Suspendisse massa sem, euismod id justo ac, semper porta sem. Cras et lacus ac tortor
          imperdiet rutrum. Suspendisse nisi augue, porttitor a mattis ut, accumsan at tellus. Aenean elit dui, aliquet
          molestie fermentum sollicitudin, rutrum quis neque. Quisque feugiat sodales metus, at finibus erat molestie
          eget.
        </p>

        <p>
          Sed sed finibus leo. Nam quis sagittis odio, id faucibus quam. Praesent vitae posuere elit. Maecenas sed
          cursus
          ante. Nunc faucibus magna vitae viverra pretium. Sed pulvinar non quam id rhoncus. Integer tincidunt dapibus
          dictum. Suspendisse vulputate sagittis vulputate. Nulla lacus diam, ultrices non consequat vitae, sollicitudin
          sit amet massa. Nunc non placerat ligula, id convallis elit. Proin quam nisi, mollis efficitur ultricies sit
          amet, congue eget leo.
        </p>

        <p>
          Praesent sit amet magna vel urna elementum mollis. Nam placerat egestas sapien, vel condimentum augue
          pellentesque ut. Proin non dapibus purus. Vivamus pulvinar tincidunt justo a lobortis. Morbi suscipit placerat
          ante, eget aliquam velit vestibulum ac. Aliquam mi nibh, consectetur at nisi a, fringilla auctor felis. Duis
          eu
          leo quis leo laoreet iaculis. Integer molestie, quam non tristique feugiat, nulla dui vehicula erat, non
          consectetur justo nisi non sapien. Aenean sagittis sapien et libero aliquam, et pretium velit sodales. Donec
          vitae dictum enim.
        </p>

        <p>
          Vestibulum blandit odio vitae facilisis bibendum. Donec quis suscipit ligula, sit amet facilisis magna.
          Aliquam
          erat volutpat. Curabitur non massa sit amet justo sodales vehicula sed a dolor. Vestibulum vel nisi velit.
          Aliquam luctus eleifend urna. Sed quis metus sem. Phasellus vehicula imperdiet sapien vel dignissim.
        </p>

        <p>
          Donec vitae lobortis libero. Proin tempus hendrerit est, vel scelerisque ligula venenatis non. Donec sed
          tristique nunc, ac sodales nisl. Aliquam venenatis, velit vitae egestas auctor, arcu ligula congue leo, quis
          bibendum tortor augue vitae libero. Nulla eleifend ligula a felis egestas sollicitudin. Quisque tristique
          lacus
          lacus, et aliquet dolor commodo in. Duis vestibulum, nunc condimentum fringilla sodales, leo libero placerat
          leo, viverra tincidunt sapien risus at leo. Ut pharetra facilisis mi, id tincidunt ligula egestas ut.
          Phasellus
          interdum libero venenatis, sagittis purus vitae, accumsan nunc. Donec accumsan enim a risus laoreet, a ornare
          ipsum varius. Sed pretium faucibus egestas. Nullam non nisi neque. Vivamus lorem lorem, molestie et lectus
          vitae, imperdiet mollis est. Duis suscipit sapien a leo sagittis, vel sagittis dolor placerat.
        </p>

        <p>
          Sed sed finibus leo. Nam quis sagittis odio, id faucibus quam. Praesent vitae posuere elit. Maecenas sed
          cursus
          ante. Nunc faucibus magna vitae viverra pretium. Sed pulvinar non quam id rhoncus. Integer tincidunt dapibus
          dictum. Suspendisse vulputate sagittis vulputate. Nulla lacus diam, ultrices non consequat vitae, sollicitudin
          sit amet massa. Nunc non placerat ligula, id convallis elit. Proin quam nisi, mollis efficitur ultricies sit
          amet, congue eget leo.
        </p>

        <p>
          Praesent sit amet magna vel urna elementum mollis. Nam placerat egestas sapien, vel condimentum augue
          pellentesque ut. Proin non dapibus purus. Vivamus pulvinar tincidunt justo a lobortis. Morbi suscipit placerat
          ante, eget aliquam velit vestibulum ac. Aliquam mi nibh, consectetur at nisi a, fringilla auctor felis. Duis
          eu
          leo quis leo laoreet iaculis. Integer molestie, quam non tristique feugiat, nulla dui vehicula erat, non
          consectetur justo nisi non sapien. Aenean sagittis sapien et libero aliquam, et pretium velit sodales. Donec
          vitae dictum enim.
        </p>
      </div>
    </div>
  )
}