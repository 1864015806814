// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextPage_formContainer__A6PZH {
  width: 100%;
  padding-inline: 15px;
  padding-bottom: 10px;
}
.TextPage_formContainer__A6PZH .TextPage_island__eRdZ7 {
  background: #c8e0ca;
  border-radius: 10px;
  padding: 20px 10px;
}
.TextPage_formContainer__A6PZH .TextPage_contentContainer__XXhUO {
  padding-inline: 100px;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/pages/TextPage/TextPage.module.scss","webpack://./src/styles/colors.module.scss"],"names":[],"mappings":"AAEA;EACI,WAAA;EACA,oBAAA;EACA,oBAAA;AADJ;AAGI;EACI,mBCPC;EDQD,mBAAA;EACA,kBAAA;AADR;AAII;EACI,qBAAA;EACA,eAAA;AAFR","sourcesContent":["@import \"src/styles/colors.module\";\n\n.formContainer {\n    width: 100%;\n    padding-inline: 15px;\n    padding-bottom: 10px;\n\n    .island {\n        background: $island;\n        border-radius: 10px;\n        padding: 20px 10px;\n    }\n\n    .contentContainer {\n        padding-inline: 100px;\n        font-size: 14px;\n    }\n}","$balance: #1cd900;\n$island: #c8e0ca;\n$border: rgba(5, 5, 5, 0.06);\n$selected: #f8bc48;\n$dot: rgba(0, 0, 0, 0.25);\n$primary: #f8bc48;\n$greenGradient: linear-gradient(0.5turn, rgb(128, 186, 67), rgb(121, 198, 69), rgb(104 157 65));\n\n// закоментить или удалить нижнюю\n// $island: #ccc;\n// $primary: #ccc;\n// $balance: #ccc;\n// $selected: #ccc;\n// $greenGradient: #ccc;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": `TextPage_formContainer__A6PZH`,
	"island": `TextPage_island__eRdZ7`,
	"contentContainer": `TextPage_contentContainer__XXhUO`
};
export default ___CSS_LOADER_EXPORT___;
