import {FC, useState} from "react";
import styles from './PackageCheckPage.module.scss'
import {PackageCheckDataProvider, usePackageCheckData} from "@pages/PackageCheckPage/hooks/usePackageCheckData";
import {PackageList} from "@pages/PackageCheckPage/components/PackageList";
import {useQuery} from "@utils/useQuery";
import {PackageDetail} from "@pages/PackageCheckPage/components/PackageDetail";
import {Spin} from "antd";
import {Exists} from "@utils/Exists";
import {PSearch} from "@pages/PackageCheckPage/components/PSearch";

interface Props {
  setSelectedPackage: (id: string) => void;
}

export const PackageCheckPageContent: FC<Props> = ({setSelectedPackage}) => {
  const {loading} = usePackageCheckData();
  return (
    <Spin spinning={loading}>
      <PSearch containerClassName={styles.searchSmall} setPackageId={setSelectedPackage}/>
      <div className={styles.packageCheckPageContainer}>
        <PackageList/>
        <PackageDetail/>
      </div>
    </Spin>
  )
}

export const PackageCheckPage = () => {
  const searchParams = useQuery();
  const [selectedPackage, setSelectedPackage] = useState<number | string | null>(searchParams.get('id'));
  return (
    <PackageCheckDataProvider defaultSelected={selectedPackage}>
      <Exists visible={!selectedPackage}>
        <PSearch setPackageId={setSelectedPackage}/>
      </Exists>
      <Exists visible={!!selectedPackage}>
        <PackageCheckPageContent setSelectedPackage={setSelectedPackage} />
      </Exists>
    </PackageCheckDataProvider>
  )
}