import {FC} from 'react';
import {BrowserRouter, Redirect, Route, RouteProps, Switch} from 'react-router-dom';
import {ConfigProvider} from 'antd';
import locale from 'antd/lib/locale/ru_RU';

import {Login} from "@components/Login/Login";
import {ModalsContainer} from "@components/modals";
import {Register} from "@components/Register/Register";
import {CreateRequestPage} from "@pages/CreateRequestPage/CreateRequestPage";
import {MapPage} from "@pages/MapPage/MapPage";
import {PackageCheckPage} from "@pages/PackageCheckPage/PackageCheckPage";
import {RequestsPage} from "@pages/RequestsPage/RequestsPage";
import {TextPage} from "@pages/TextPage/TextPage";
import {useAuth} from "@utils/auth/auth";
import {Exists} from "@utils/Exists";

import {TemplatePage} from "./templates/TemplatePage/TemplatePage";

// import 'antd/dist/antd.css';
import './styles/styles.module.scss';
import './App.css';

import 'dayjs/locale/ru';

export const ROUTER_DEFAULT_PAGE = '/request-list';

const PageNotFound = () => {
  return <Redirect to={ROUTER_DEFAULT_PAGE}/>;
};

const routeSchema: RouteExtendsProps[] = [
  {
    path: '/request-list/',
    exact: true,
    component: RequestsPage,
  },
  {
    path: '/create-request/',
    exact: true,
    component: CreateRequestPage,
  },
  {
    path: '/calculate/',
    exact: true,
    component: CreateRequestPage,
  },
  {
    path: '/tracking/',
    exact: true,
    component: PackageCheckPage,
  },
  {
    path: '/map/',
    exact: true,
    component: MapPage,
  },
  {
    path: '/text/',
    exact: true,
    component: TextPage,
  },
  {
    path: '*',
    component: PageNotFound,
  },
];

interface RouteExtendsProps extends RouteProps {
  allow?: boolean;
  pathnameRegex?: string;
}

const App: FC = () => {
  const [logged] = useAuth();
  const pageRoute = routeSchema.find(
    (item) => item.pathnameRegex && window.location.pathname.match(item.pathnameRegex)
  );
  const pageRequireLogin = pageRoute ? !pageRoute.allow : true;

  return (
    <div className='App'>
      <BrowserRouter>
        <>
          <ModalsContainer/>

          <Exists visible={!logged}>
            <Switch>
              <Route exact path='/login' component={Login}/>
              <Route exact path='/register' component={Register}/>
              <Redirect to='/login'/>
            </Switch>
          </Exists>

          {logged && pageRequireLogin && (
            <ConfigProvider locale={locale}>
              <TemplatePage>
                <Switch>
                  {routeSchema.map((item: RouteExtendsProps) => (
                    <Route key={String(item.path)} {...item} />
                  ))}
                </Switch>
              </TemplatePage>
            </ConfigProvider>
          )}
        </>
      </BrowserRouter>
    </div>
  );
};

export default App;
