import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestListItem } from '@typings/pages/requestPageTypes';

// Define a type for the slice state
interface State {
  data: RequestListItem[] | null;
  showHintsForOrders: boolean;
  refreshDataTrigger: boolean;
}

// Define the initial state using that type
const initialState: State = {
  data: null,
  showHintsForOrders: false,
  refreshDataTrigger: false,
};

export const selectedOrders = createSlice({
  name: 'selectedOrders',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSelectedOrders: (state, action: PayloadAction<State['data']>) => {
      state.data = action.payload;
    },
    toggleShowHintsForOrders: (state, action: PayloadAction<boolean | undefined>) => {
      state.showHintsForOrders = action.payload ?? !state.showHintsForOrders;
    },
    refreshDataTrigger: state => {
      state.refreshDataTrigger = !state.refreshDataTrigger;
      state.data = null;
    },
  },
});

export const { setSelectedOrders, toggleShowHintsForOrders, refreshDataTrigger } = selectedOrders.actions;

export default selectedOrders.reducer;
