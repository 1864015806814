import { FullscreenControl } from '@pbe/react-yandex-maps';
import { FC, useEffect, useReducer, useRef, useState } from 'react';

type FullscreenControlComponentProps = {
  setOpenMap: React.Dispatch<React.SetStateAction<boolean>>;
  setMap: any;
  ref: any;
};

const FullscreenControlComponent: FC<FullscreenControlComponentProps> = ({ setOpenMap, setMap, ref }) => {
  const myMap = useRef<any>(null);

  const [isUpdate, update] = useReducer((state: number) => state + 1, 0);
  const [stop, setStop] = useState(false);

  // Навешивание событий на кнопку. По нормальному не вешаются что-то.
  // Поэтому приходится так исхитриться
  useEffect(() => {
    if (myMap.current) {
      setStop(true);
      myMap.current.events.add('fullscreenenter', () => setOpenMap(true));
      myMap.current.events.add('fullscreenexit', () => setOpenMap(false));
    }
  }, [isUpdate]);

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (!stop) {
      interval = setInterval(() => {
        update();
      }, 500);
    } else clearTimeout(interval!);

    return () => {
      clearTimeout(interval);
    };
  }, [stop]);

  // Фикс карты при входе-выходе в полноэкранный режим
  const onResize = () => {
    if (ref?.current) {
      setMap({
        width: `${ref.current?.clientWidth}px`,
        height: `${ref.current?.clientHeight}px`,
      });
    }
  };

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <FullscreenControl
      instanceRef={myMap}
      options={{
        // @ts-ignore
        exitFullscreenByEsc: true,
      }}
    />
  );
};

export default FullscreenControlComponent;
