import { TableColumnType } from 'antd';
import xlsx from 'better-xlsx';
import objectPath from 'object-path';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import './font';
import { ReactElement } from 'react';

const useExport = <RecordType extends object>({
  columns,
  data,
  fileName,
  pdfTheme,
}: {
  columns: TableColumnType<RecordType>[];
  data: RecordType[];
  fileName: string;
  pdfTheme?: 'striped' | 'grid' | 'plain';
}) => {
  const onExcelPrint = () => {
    const file = new xlsx.File();
    const sheet = file.addSheet('Sheet1');
    const headerRow = sheet.addRow();
    columns.forEach(({ title }) => {
      const cell = headerRow.addCell();
      cell.value = title;
    });
    data.forEach(record => {
      const row = sheet.addRow();
      columns.forEach(column => {
        const cell = row.addCell();
        cell.value = objectPath.get(record, column.dataIndex as objectPath.Path);
        if (column.render) cell.value = renderCell(column, record);
      });
    });

    file.saveAs('blob').then((blob: Blob) => {
      saveAs(blob, `${fileName}.xlsx`);
    });
  };

  const renderCell = (column: TableColumnType<RecordType>, record: RecordType) => {
    if (!column) return '';

    if (column.render) {
      const data = column.render(objectPath.get(record, column.dataIndex as objectPath.Path), record, 0);
      if (typeof data === 'object') {
        return (data as ReactElement)?.props?.children || '';
      }
      return data;
    }

    return objectPath.get(record, column.dataIndex as objectPath.Path);
  };

  const onCsvPrint = () => {
    let csv = '';
    columns.forEach(({ title }, index) => {
      if (index !== 0) csv += ',';

      csv += `${(title as string)?.replaceAll('"', '""')}`;
    });

    csv += '\n';

    data.forEach(record => {
      columns.forEach((column, index) => {
        if (index !== 0) csv += ',';
        let data = '';
        if (column.render) {
          data = `${renderCell(column, record)}`;
        } else data = String(objectPath.get(record, column.dataIndex as objectPath.Path)).replaceAll('"', '""');
        csv += `${data.replaceAll(',', ' ')}`;
      });
      csv += '\n';
    });

    saveAs(new Blob([csv]), `${fileName}.csv`);
  };

  const onPdfPrint = () => {
    const doc = new jsPDF({
      orientation: 'landscape',
    });
    doc.setFont('FreeSans');

    autoTable(doc, {
      styles: { font: 'FreeSans' },
      headStyles: { fontStyle: 'normal' },
      head: [columns.map(c => c.title as string)],
      body: data.map(r =>
        columns.map(c => {
          if (!c.render) return objectPath.get(r, c.dataIndex as objectPath.Path);
          return renderCell(c, r);
        }),
      ),
      theme: pdfTheme,
    });

    doc.save(`${fileName}.pdf`);
  };

  return {
    onExcelPrint,
    onCsvPrint,
    onPdfPrint,
  };
};

export default useExport;
