import {ServiceFetchType} from "@typings";
import {DADATA_API_KEY} from "@constants";
import Axios from "axios";

export const getAddressDataByCoordinates: ServiceFetchType<
  any,
  { coordinates: [number, number] }
> = ({
       params,
     }) => {
  return Axios(`https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address?lat=${params?.coordinates[0]}&lon=${params?.coordinates[1]}`, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${DADATA_API_KEY}`
    },
  })
}

export const getCountriesList: ServiceFetchType<any, { search: string }> = ({
                                                                              params,
                                                                            }) => {
  return Axios(`https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/country`, {
    method: 'POST',
    headers: {
      'Authorization': `Token ${DADATA_API_KEY}`
    },
    data: {
      query: params?.search
    }
  })
}