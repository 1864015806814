import React, {FC, useEffect, useState} from 'react';
import {Button, Checkbox, Divider, Form, Input, Row, Select, Space, Typography} from 'antd';
import styles from '../RequestsPage.module.scss';
import {MinusOutlined, PlusOutlined, SearchOutlined} from '@ant-design/icons';
import {useCRequest} from '@pages/CreateRequestPage/hooks/useCRequest';
import CRMap from '@pages/CreateRequestPage/components/CRMap';
import clsx from 'clsx';
import {deliveryToTypes, packageTypes} from '@typings/pages/createRequestTypes';
import {Exists} from '@utils/Exists';
import {numberRules, numberRulesRequired, requiredRules, textRulesRequired} from '@typings/rules';
import {MaskedInput} from 'antd-mask-input';
import {phoneMask} from '@typings/masks';
import {BtnHint} from '@components/BtnHint';
import {DeliveryTypeSelect} from '@components/DeliveryTypeSelect';
import {DaDataAnyAutoComplete, DAVOptionType, DCAOptionType} from '@components/DaDataCountryAutoComplete';
import {BaseOptionType, DefaultOptionType} from 'rc-select/lib/Select';
import {CRPageMode} from '@typings/models/orders';
import {useHistory} from 'react-router-dom';
import {getOnlyField, getOnlyHouse, getOnlyStreet, withHouse} from './utils';
import {useAppSelector} from '@store';
// import setTimeout = jest.setTimeout;


const ExtraPriceIsland: FC = () => {
  return (
    <div className={clsx(styles.extraCard, styles.island, styles.smGap)}>
      <div className={clsx(styles.topLabelContainer, styles.secondDoubleCell)}>
        <Typography.Text>Добавить к стоимости (своя наценка)</Typography.Text>
        <Form.Item name={'additional_cost'}>
          <Input className={styles.w260} autoComplete='new-password' placeholder={'Добавить к стоимости'}/>
        </Form.Item>
      </div>
    </div>
  )
}

const OrderIsland: FC = () => {
  const {data: availablePVZ} = useAppSelector(state => state.availablePVZ);
  const {
    form,
    streetPreValue,
    setStreetPreValue,
    housePreValue,
    setHousePreValue,
    mapOptions,
    pageMode,
    onChangeForm,
    getPreValue,
    cityIndex,
    setCityIndex,
    checkAddressWithPVS,
    setCheckAddressWithPVS,
  } = useCRequest();

  const onAddressChange = (item: DAVOptionType) => {
    const value = item?.value || item?.fullValue?.value;
    if (item?.fullValue) {
      const cityVisible = getPreValue({item, text: value});
      const street = getPreValue({
        item,
        text: `${cityVisible} ${value}`,
        withStreet: true,
      });

      form.setFieldValue('city', item?.fullValue);
      form.setFieldValue('cityVisible', cityVisible);

      setStreetPreValue(cityVisible);

      form.setFieldValue('visible_full_address', street);

      if (getOnlyStreet(item)) {
        form.setFieldValue('street', getOnlyStreet(item));
        form.setFieldValue('streetVisible', getOnlyStreet(item));

        setHousePreValue(street);

        if (getOnlyHouse(item)) {
          form.setFieldValue('house', getOnlyHouse(item));
        } else {
          form.setFieldValue('house', undefined);
        }
      } else {
        form.setFieldValue('street', undefined);
        form.setFieldValue('streetVisible', undefined);
        form.setFieldValue('house', undefined);
        setHousePreValue('');
      }
    } else {
      // form.setFieldValue('city', undefined);
      // form.setFieldValue('cityVisible', undefined);
      form.setFieldValue('street', undefined);
      form.setFieldValue('streetVisible', undefined);
      form.setFieldValue('house', undefined);
      setStreetPreValue('');
      setHousePreValue('');
    }
    form.setFieldValue('apartment', getOnlyField(item, 'flat'));
    form.setFieldValue('index', getOnlyField(item, 'postal_code'));
    setCityIndex(getOnlyField(item, 'postal_code'));
    onChangeForm();
  };

  const onCheckDeliveryPVZ = () => {
    const delivery_to_type = form.getFieldValue('delivery_to_type');

    if (delivery_to_type === 'pvz') {
      const delivery_type = form.getFieldValue('delivery_type');

      if (!delivery_type) {
        form.validateFields(['delivery_type']);
      }
    }
  };

  const onBlur = () => {
    const delivery_to_type = form.getFieldValue('delivery_to_type');

    if (delivery_to_type === 'pvz') {
      const delivery_type = form.getFieldValue('delivery_type');

      if (delivery_type) {
        setCheckAddressWithPVS(true);
      }
    }
  };

  const onChangeDeliveryType = (value: any, field: any) => {
    const delivery_to_type = field === 'delivery_to_type' ? value : form.getFieldValue('delivery_to_type');
    const delivery_type = field === 'delivery_type' ? value : form.getFieldValue('delivery_type');

    if (delivery_to_type !== 'pvz') {
      form.setFields([
        {
          name: 'streetVisible',
          errors: undefined,
        },
        {
          name: 'house',
          errors: undefined,
        },
        {
          name: 'visible_full_address',
          errors: undefined,
        },
      ]);
    }

    setCheckAddressWithPVS(delivery_to_type === 'pvz' && delivery_type);
  };

  useEffect(() => {
    if (checkAddressWithPVS) {
      setCheckAddressWithPVS(false);
      const pointsPVS = (availablePVZ || [])
        .map(item => {
          const value = `${item.formAddress.street_with_type || item.formAddress.settlement_with_type}, ${
            item.formAddress.house_type
          } ${item.formAddress.house}`;

          return {
            label: value,
            value: value,
            fullValue: {
              value: value,
              unrestricted_value: value,
              data: {
                ...(item.formAddress as unknown as DCAOptionType['fullValue']['data']),
                geo_lat: item.lat,
                geo_lon: item.lon,
              },
            },
          };
        })
        .filter((item, index, self) => self.findIndex(t => t.value === item.value) === index);

      const streetVisible = form.getFieldValue('streetVisible');
      const house = form.getFieldValue('house');

      if (pointsPVS.length > 0) {
        let deleteStreet = true;
        let deleteHouse = true;
        let stop = false;

        pointsPVS.forEach(item => {
          const streetCheck = getOnlyField(item, 'street') && streetVisible?.includes(getOnlyField(item, 'street'));
          const settlementCheck =
            getOnlyField(item, 'settlement') && streetVisible?.includes(getOnlyField(item, 'settlement'));

          if (!stop) {
            if (item.value.includes(streetVisible) && (streetCheck || settlementCheck)) {
              deleteStreet = false;
              if (item.value.includes(house) && house?.includes(getOnlyField(item, 'house'))) {
                deleteHouse = false;
                stop = true;
              }
            }
          }
        });

        if (deleteStreet || deleteHouse) {
          const city = form.getFieldValue('city');
          const cityVisible = form.getFieldValue('cityVisible');

          form.setFields([
            {
              name: 'visible_full_address',
              errors: ['Выбор адреса до ПВЗ доступен только в левом меню'],
            },
          ]);

          if (deleteHouse) {
            form.setFieldValue('house', undefined);
            form.setFieldValue('apartment', undefined);
            form.setFieldValue('visible_full_address', `${cityVisible}, ${streetVisible}`);
            form.setFieldValue('city', {
              ...city,
              data: {
                ...city?.data,
                house: undefined,
                flat: undefined,
              },
            });
            setHousePreValue(`${cityVisible}, ${streetVisible}`);

            form.setFields([
              {
                name: 'house',
                errors: [''],
              },
            ]);
          }

          if (deleteStreet) {
            form.setFieldValue('house', undefined);
            form.setFieldValue('street', undefined);
            form.setFieldValue('streetVisible', undefined);
            form.setFieldValue('apartment', undefined);
            form.setFieldValue('visible_full_address', cityVisible);
            form.setFieldValue('city', {
              ...city,
              data: {
                ...city?.data,
                house: undefined,
                flat: undefined,
                street_with_type: undefined,
              },
            });
            setStreetPreValue(cityVisible);
            setHousePreValue(cityVisible);

            form.setFields([
              {
                name: 'house',
                errors: [''],
              },
              {
                name: 'streetVisible',
                errors: [''],
              },
            ]);
          }
        }

        setTimeout(onChangeForm, 0);
      }
    }
  }, [checkAddressWithPVS, availablePVZ]);

  const onChangeStreet = (_: any, item: BaseOptionType) => {
    const value = item?.value || item?.fullValue?.value;

    onCheckDeliveryPVZ();

    if (getOnlyStreet(item as any)) {
      form.setFieldValue('street', getOnlyStreet(item as any));
      form.setFieldValue('streetVisible', getOnlyStreet(item as any));
      form.setFieldValue('pvzCode', item.pvzCode);

      const cityVisible = getPreValue({item, text: value});

      let street = '';

      if (!streetPreValue) {
        form.setFieldValue('cityVisible', cityVisible);
        street = getPreValue({
          item,
          text: `${cityVisible} ${value}`,
          withStreet: true,
        });
      } else {
        street = getPreValue({
          item,
          text: `${streetPreValue} ${value}`,
          withStreet: true,
        });
      }
      setHousePreValue(street || '');
      form.setFieldValue('visible_full_address', withHouse(street, item as any));

      if (item?.fullValue) {
        form.setFieldValue('city', item?.fullValue);
      }
    } else {
      setHousePreValue('');
      form.setFieldValue('street', undefined);
    }

    form.setFieldValue('index', getOnlyField(item, 'postal_code') || cityIndex);
    form.setFieldValue('house', getOnlyHouse(item as any));
    if (getOnlyHouse(item as any)) {
      form.setFields([
        {
          name: 'house',
          errors: undefined,
        },
      ]);
    }

    const delivery_to_type = form.getFieldValue('delivery_to_type');
    const delivery_type = form.getFieldValue('delivery_type');

    if (delivery_to_type === 'pvz' && delivery_type && getOnlyField(item, 'house')) {
      form.validateFields(['house']);
    }
    form.setFields([
      {
        name: 'visible_full_address',
        errors: undefined,
      },
    ]);

    onChangeForm();
  };

  const onChangeHouse = (_: any, item: BaseOptionType) => {
    onCheckDeliveryPVZ();

    if (getOnlyField(item, 'house')) {
      form.setFieldValue('house', getOnlyHouse(item as any));
      form.setFieldValue('visible_full_address', withHouse(housePreValue, item as any));
      form.setFieldValue('apartment', getOnlyField(item, 'flat'));
      form.setFieldValue('pvzCode', item.pvzCode);

      if (item?.fullValue) {
        form.setFieldValue('city', item?.fullValue);
      }
    } else {
      form.setFieldValue('apartment', undefined);
    }

    const delivery_to_type = form.getFieldValue('delivery_to_type');
    const delivery_type = form.getFieldValue('delivery_type');

    if (delivery_to_type === 'pvz' && delivery_type && getOnlyStreet(item as any)) {
      const value = item?.value || item?.fullValue?.value;
      const street = getPreValue({
        item,
        text: `${streetPreValue} ${value}`,
        withStreet: true,
      });
      form.setFieldValue('street', getOnlyStreet(item as any));
      form.setFieldValue('streetVisible', getOnlyStreet(item as any));
      setHousePreValue(street || '');
      form.setFieldValue('visible_full_address', withHouse(street, item as any));
      form.validateFields(['streetVisible']);
    }
    form.setFields([
      {
        name: 'visible_full_address',
        errors: undefined,
      },
    ]);

    onChangeForm();
  };

  return (
    <div className={clsx(styles.orderCard, styles.island, styles.smGap)}>
      <Form.Item name={'country'} hidden noStyle/>
      <Form.Item name={'city'} hidden noStyle/>
      <Form.Item name={'street'} hidden noStyle/>
      <Space direction={'vertical'}>
        <Exists visible={pageMode === CRPageMode.create}>
          <Form.Item
            rules={requiredRules}
            required
            label={'Заказ №'}
            name={'order_number'}
            labelCol={{span: 7}}
            className={styles.labelAlignLeft}
            colon={false}
          >
            <Input autoComplete='off' placeholder={'Введите номер заказа'}/>
          </Form.Item>
        </Exists>
        <Form.Item
          rules={requiredRules}
          required
          name={'countryVisible'}
          label={String.fromCharCode(160)}
          labelCol={pageMode === CRPageMode.create ? {span: 7} : undefined}
          colon={false}
        >
          <DaDataAnyAutoComplete
            autoComplete='off'
            url={'country'}
            onChange={(_, item: DCAOptionType) => {
              form.setFieldValue('country', item?.fullValue);
              form.resetFields(['city', 'cityVisible', 'street', 'streetVisible', 'house', 'apartment', 'index']);
              setCityIndex(undefined);

              onChangeForm();
            }}
            placeholder={'Введите страну'}
          />
        </Form.Item>
        <Form.Item
          rules={requiredRules}
          required
          name={'cityVisible'}
          label={String.fromCharCode(160)}
          labelCol={pageMode === CRPageMode.create ? {span: 7} : undefined}
          colon={false}
        >
          <DaDataAnyAutoComplete
            url={'address'}
            onSelect={(_, item: BaseOptionType) => onAddressChange(item as DAVOptionType)}
            onChange={(_, item: BaseOptionType) => onAddressChange(item as DAVOptionType)}
            placeholder={'Введите город'}
            autoComplete='new-password'
            prefix='г'
          />
        </Form.Item>
      </Space>
      <Space direction={'vertical'}>
        <Form.Item name='delivery_type' rules={requiredRules}>
          <DeliveryTypeSelect
            onChange={value => onChangeDeliveryType(value, 'delivery_type')}
            className={styles.deliveryTypeSelect}
            placeholder='Выберите ТК'
          />
        </Form.Item>
        <Form.Item name='delivery_to_type' rules={requiredRules}>
          <Select
            options={Object.keys(deliveryToTypes).map(key => ({
              label: deliveryToTypes[key as keyof typeof deliveryToTypes],
              value: key,
            }))}
            onChange={value => onChangeDeliveryType(value, 'delivery_to_type')}
            className={styles.deliveryTypeSelect}
            placeholder='Выберите доставку'
          />
        </Form.Item>
        <div className={clsx(styles.smGap, styles.inlineInputRow, styles.four)}>
          <Form.Item
            className={styles.formItemWithFloatingLabel}
            name={'streetVisible'}
            rules={requiredRules}
            required
            label={String.fromCharCode(160)}
            colon={false}
          >
            <DaDataAnyAutoComplete
              autoComplete='new-password'
              url={'address'}
              forwardValue={streetPreValue}
              onChange={onChangeStreet}
              onFocus={onCheckDeliveryPVZ}
              onBlur={() => {
                const street = form.getFieldValue('streetVisible');
                if (!street) {
                  form.setFieldValue('street', undefined);
                  form.setFieldValue('house', undefined);
                  form.validateFields(['streetVisible', 'house']);
                } else {
                  const delivery_to_type = form.getFieldValue('delivery_to_type') === 'pvz';

                  if (delivery_to_type && street) {
                    form.setFieldValue('streetVisible', street);

                    setTimeout(() => onBlur(), 0);
                  }
                }
              }}
              onSelect={onChangeStreet}
              placeholder={'Улица'}
              prefix='ул'
              forPvz={mapOptions.delivery_to_type === 'pvz'}
              style={{minWidth: 150}}
            />
          </Form.Item>
          <Form.Item name={'house'} rules={requiredRules} required label={String.fromCharCode(160)} colon={false}>
            <DaDataAnyAutoComplete
              autoComplete='new-password'
              url={'address'}
              forwardValue={housePreValue}
              onChange={onChangeHouse}
              onSelect={onChangeHouse}
              onFocus={onCheckDeliveryPVZ}
              onBlur={() => {
                const house = form.getFieldValue('house');
                if (!house) {
                  form.setFieldValue('house', undefined);
                }

                const delivery_to_type = form.getFieldValue('delivery_to_type') === 'pvz';
                if (delivery_to_type) {
                  form.setFieldValue('house', house);
                }

                form.validateFields(['house']);
                setTimeout(() => onBlur(), 0);
              }}
              placeholder={'Дом'}
              forPvz={mapOptions.delivery_to_type === 'pvz'}
              style={{minWidth: 80}}
              prefix='д'
            />
            {/* <Input autoComplete='new-password' p'laceholder={Дом'} /> */}
          </Form.Item>
          <Form.Item name={'apartment'} rules={numberRules}>
            <Input
              autoComplete='new-password'
              onFocus={onCheckDeliveryPVZ}
              placeholder={'Квартира'}
              style={{minWidth: 60}}
            />
          </Form.Item>
          <Form.Item name={'index'} rules={numberRules}>
            <Input autoComplete='off' placeholder={'Индекс'} style={{minWidth: 70}}/>
          </Form.Item>
        </div>
      </Space>
    </div>
  );
};

const RecipientIsland: FC = () => {
  const {form} = useCRequest();
  const onChangeCheckbox = () => {
    form.setFields([
      {
        name: 'sender_pays',
        errors: undefined,
      },
      {
        name: 'recipient_pays',
        errors: undefined,
      },
    ]);
  };

  return (
    <div className={clsx(styles.orderCard, styles.island, styles.smGap)}>
      <Space direction={'vertical'}>
        <Form.Item
          className={styles.labelAlignLeft}
          rules={textRulesRequired}
          required
          name={'recipient_name'}
          label={'Получатель'}
          labelCol={{span: 7}}
          colon={false}
        >
          <Input
            readOnly
            onFocus={e => e.target.removeAttribute('readonly')}
            type='text'
            autoComplete='new-password'
            placeholder={'ФИО'}
          />
        </Form.Item>
        <Form.Item
          className={styles.labelAlignLeft}
          rules={textRulesRequired}
          required
          name={'sender_name'}
          label={'Отправитель'}
          labelCol={{span: 7}}
          colon={false}
        >
          <Input
            readOnly
            onFocus={e => e.target.removeAttribute('readonly')}
            autoComplete='new-password'
            placeholder={'ФИО'}
          />
        </Form.Item>
      </Space>
      <Space direction={'vertical'}>
        <div className={clsx(styles.smGap, styles.inlineInputRow, styles.withCheckBox)}>
          <Form.Item rules={requiredRules} required name={'recipient_phone'} label={'ТЕЛ.'} colon={false}>
            <MaskedInput autoComplete='new-password' mask={phoneMask} placeholder={'Телефон'}/>
          </Form.Item>
          <Form.Item
            name={'recipient_pays'}
            label={'Оплачивает'}
            colon={false}
            valuePropName={'checked'}
            labelCol={{className: styles.smallTopLabel}}
          >
            <Checkbox onChange={onChangeCheckbox}/>
          </Form.Item>
        </div>
        <div className={clsx(styles.smGap, styles.inlineInputRow, styles.withCheckBox)}>
          <Form.Item rules={requiredRules} required name={'sender_phone'} label={'ТЕЛ.'} colon={false}>
            <MaskedInput autoComplete='new-password' mask={phoneMask} placeholder={'Телефон'}/>
          </Form.Item>
          <Form.Item name={'sender_pays'} valuePropName={'checked'}>
            <Checkbox onChange={onChangeCheckbox}/>
          </Form.Item>
        </div>
      </Space>
    </div>
  );
};

const PackageIsland: FC = () => {
  const history = useHistory();
  const {
    onCalculate,
    form,
    pageMode,
    onCreateDelivery,
    deliveryCostCalculated,
    packageCount,
    setPackageCount,
    streetPreValue,
    mapOptions,
  } = useCRequest();
  const [newItemType, setNewItemType] = useState<string>();
  const [typeOptions, setTypeOptions] = useState<DefaultOptionType[]>(Object.keys(packageTypes).map(key => ({
    label: packageTypes[key as keyof typeof packageTypes],
    value: key,
  })));
  const reducePackageCount = () => {
    if (packageCount > 1) {
      setPackageCount(packageCount - 1);
    }
  };

  const onChangeToCreatePage = () => {
    form.validateFields().then(values => {
      history.push({
        pathname: '/create-request',
        search: '?page=create',
        state: {
          ...values,
          packageCount,
          streetPreValue,
          providerId: mapOptions.providerId,
          centerPoint: mapOptions.centerPoint,
        },
      });
    });
  };

  const increasePackageCount = () => {
    setPackageCount(packageCount + 1);
  };

  const onNewTypeAdd = (e: any) => {
    e.preventDefault();
    if (newItemType) {
      setTypeOptions([...typeOptions, {label: newItemType, value: newItemType}]);
      setTimeout(
        () => form.setFieldsValue({
          package_type: newItemType,
        }),
        0
      );
      setNewItemType(undefined);
    }
  }


  return (
    <div className={clsx(styles.packageCard, styles.island, styles.smGap)}>
      <Exists visible={pageMode === CRPageMode.create}>
        <Form.Item
          className={styles.formItemWithFloatingLabel}
          name='package_type'
          rules={requiredRules}
          required
          label={String.fromCharCode(160)}
          colon={false}
        >
          <Select
            options={typeOptions}
            className={styles.deliveryTypeSelect}
            placeholder='Тип вложения'
            dropdownRender={menu => (
              <>
                {menu}
                <Divider style={{margin: '8px 0'}}/>
                <Space style={{padding: '0 8px 4px'}}>
                  <Input
                    placeholder="Другое"
                    // ref={inputRef}
                    value={newItemType}
                    onChange={(e) => {
                      e.preventDefault();
                      setNewItemType(e.target.value)
                    }}
                    onPressEnter={onNewTypeAdd}
                  />
                  <Button disabled={!newItemType} type="text" onClick={onNewTypeAdd}>
                    Выбрать
                  </Button>
                </Space>
              </>
            )}
          />
        </Form.Item>
      </Exists>
      <Exists visible={pageMode === CRPageMode.calculate}>
        <div className={styles.packageDivider}/>
      </Exists>
      {[...Array(packageCount)].map((_, index) => (
        <>
          <Exists visible={index > 1}>
            <div className={styles.packageDivider}/>
          </Exists>
          <Exists visible={index === 1}>
            <div className={styles.textAlignLeft}>
              <Typography.Text>Добавить место</Typography.Text>
            </div>
          </Exists>
          <Form.Item
            className={styles.formItemWithFloatingLabel}
            name={`package_weight_${index}`}
            rules={numberRulesRequired}
            required
            label={String.fromCharCode(160)}
            colon={false}
          >
            <Input autoComplete='off' placeholder={'Вес (гр)'}/>
          </Form.Item>
          <Form.Item
            name={`package_length_${index}`}
            rules={numberRulesRequired}
            required
            label={String.fromCharCode(160)}
            colon={false}
          >
            <Input autoComplete='off' placeholder={'Длина (см)'}/>
          </Form.Item>
          <Form.Item
            className={styles.formItemWithFloatingLabel}
            name={`package_width_${index}`}
            rules={numberRulesRequired}
            required
            label={String.fromCharCode(160)}
            colon={false}
          >
            <Input autoComplete='off' placeholder={'Ширина (см)'}/>
          </Form.Item>
          <Form.Item
            name={`package_height_${index}`}
            rules={numberRulesRequired}
            required
            label={String.fromCharCode(160)}
            colon={false}
          >
            <Input autoComplete='off' placeholder={'Высота (см)'}/>
          </Form.Item>
          <Exists visible={index + 1 === packageCount && packageCount !== 1}>
            <Button type={'text'} onClick={reducePackageCount} icon={<MinusOutlined/>}/>
          </Exists>
          <Exists visible={index + 1 !== packageCount && index !== 0}>
            <div className={styles.packageDivider}/>
          </Exists>
          <Exists visible={index === 0}>
            <Button onClick={increasePackageCount} type={'text'} icon={<PlusOutlined/>}/>
          </Exists>
        </>
      ))}
      <div className={styles.textAlignLeft}>
        <Typography.Text>Оценочная стоимость</Typography.Text>
      </div>
      <div className={styles.doubleCell}>
        <Form.Item name='estimated_cost'>
          <Input placeholder={'Оценочная стоимость'}/>
        </Form.Item>
      </div>
      <div className={styles.secondDoubleCell}>
        <Form.Item name={'additional_cost'}>
          <Input autoComplete='new-password' placeholder={'Добавить к стоимости'}/>
        </Form.Item>
        {/*<BtnHint k={'request.create.calculate'}>*/}
        {/*  <Button className={styles.calcButton} onClick={onCalculate}>*/}
        {/*    Рассчитать*/}
        {/*  </Button>*/}
        {/*</BtnHint>*/}
      </div>
      <div className={styles.packageDivider}/>
      <div className={clsx(styles.packageDivider, styles.large)}/>
      <div className={clsx(styles.topLabelContainer, styles.doubleCell)}>
        <Typography.Text>Стоимость доставки</Typography.Text>
        <Form.Item name={'delivery_cost'}>
          <Input disabled placeholder={'Стоимость доставки'} className={styles.resultInput}/>
        </Form.Item>
      </div>
      <div className={clsx(styles.alignEnd, styles.secondDoubleCell)}>
        <BtnHint k={'request.create.calculate'}>
          <Button className={styles.calcButton} onClick={onCalculate}>
            Рассчитать
          </Button>
        </BtnHint>
      </div>
      <div className={styles.packageDivider}/>
      <div className={styles.lastLineObj}>
        <Typography.Text>ИТОГО</Typography.Text>
      </div>
      <div className={clsx(styles.doubleCell, styles.lastLineObj)}>
        <Form.Item name='total_cost'>
          <Input placeholder={'Итого'} disabled className={styles.resultInput}/>
        </Form.Item>
      </div>
      <Exists visible={pageMode === CRPageMode.create}>
        <div className={clsx(styles.secondDoubleCell, styles.lastLineObj)}>
          <BtnHint k={'request.create.btn'}>
            <Button onClick={onCreateDelivery} className={styles.CreateReqBtn}>
              Создать заказ
            </Button>
          </BtnHint>
        </div>
      </Exists>
      <Exists visible={pageMode === CRPageMode.calculate && deliveryCostCalculated}>
        <div className={clsx(styles.secondDoubleCell, styles.lastLineObj)}>
          <BtnHint k={'request.to_create.btn'}>
            <Button onClick={onChangeToCreatePage} className={styles.CreateReqBtn}>
              Перейти к созданию заказа
            </Button>
          </BtnHint>
        </div>
      </Exists>
      <div className={styles.packageDivider}/>
    </div>
  );
};

export const CreateRequestForm: FC = () => {
  const {
    form,
    pageMode,
    mapOptions,
    onPlaceMarkClick,
    onChangeForm,
    setHousePreValue,
    setStreetPreValue,
    getPreValue,
    setCityIndex,
    setCheckAddressWithPVS,
  } = useCRequest();
  const [searchValue, setSearchValue] = useState<string>('');
  const initialValues = {
    country: {
      value: 'Россия',
      unrestricted_value: 'Российская Федерация',
      data: {
        code: '643',
        alfa2: 'RU',
        alfa3: 'RUS',
        name_short: 'Россия',
        name: 'Российская Федерация',
      },
    },
    countryVisible: 'Россия',
  };

  const onAddressChange = (item: DAVOptionType) => {
    const value = item?.value || item?.fullValue?.value;
    if (item?.fullValue) {
      const cityVisible = getPreValue({item, text: value});
      const street = getPreValue({
        item,
        text: `${cityVisible} ${value}`,
        withStreet: true,
      });

      form.setFieldValue('city', item?.fullValue);
      form.setFieldValue('cityVisible', cityVisible);

      setStreetPreValue(cityVisible);

      form.setFieldValue('visible_full_address', street);

      if (getOnlyStreet(item)) {
        form.setFieldValue('street', getOnlyStreet(item));
        form.setFieldValue('streetVisible', getOnlyStreet(item));

        setHousePreValue(street);

        if (getOnlyHouse(item)) {
          form.setFieldValue('house', getOnlyHouse(item));
        } else {
          form.setFieldValue('house', undefined);
        }
      } else {
        form.setFieldValue('street', undefined);
        form.setFieldValue('streetVisible', undefined);
        setHousePreValue('');
      }
    } else {
      form.setFieldValue('street', undefined);
      form.setFieldValue('streetVisible', undefined);
      form.setFieldValue('house', undefined);
      setStreetPreValue('');
      setHousePreValue('');
    }
    form.setFieldValue('apartment', getOnlyField(item, 'flat'));
    form.setFieldValue('index', getOnlyField(item, 'postal_code'));
    setCityIndex(getOnlyField(item, 'postal_code'));

    const delivery_to_type = form.getFieldValue('delivery_to_type');
    const delivery_type = form.getFieldValue('delivery_type');
    const streetVisible = form.getFieldValue('streetVisible');
    const house = form.getFieldValue('house');

    if (delivery_to_type === 'pvz' && delivery_type && (streetVisible || house)) {
      setCheckAddressWithPVS(true);
    }

    onChangeForm();
  };

  const onChange = (_: any, item: BaseOptionType) => {
    setSearchValue((item as DAVOptionType)?.fullValue?.value);
    onAddressChange(item as DAVOptionType);
  };

  return (
    <Form form={form} initialValues={initialValues} onValuesChange={onChangeForm}>
      <Form.Item noStyle hidden name={'pvzCode'}/>
      <Space className={clsx(styles.formContainer, styles.formWithReversedStars)}>
        <div className={styles.formColsContainer}>
          <div className={styles.LeftColItems}>
            <ExtraPriceIsland/>
            <OrderIsland/>
            <Exists visible={pageMode === CRPageMode.create}>
              <RecipientIsland/>
            </Exists>
            <PackageIsland/>
          </div>
          <div className={clsx(styles.island, styles.mapContainer)}>
            <Form.Item name={`visible_full_address`} required>
              <DaDataAnyAutoComplete
                autoComplete='off'
                url={'address'}
                onSelect={onChange}
                onChange={onChange}
                placeholder={'Выбрать на карте'}
              />
            </Form.Item>
            <CRMap
              onPlaceMarkClick={onPlaceMarkClick}
              search={searchValue}
              providerId={mapOptions.providerId}
              country={mapOptions.countryCode}
              centerPoint={mapOptions.centerPoint}
              zoom={mapOptions.zoom}
              form={form}
            />
          </div>
        </div>
      </Space>
    </Form>
  );
};
