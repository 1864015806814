import {RequestListItem} from '@typings/pages/requestPageTypes';

export interface getDeliveryCompaniesParams {
}

export interface DeliveryCompanyItem {
  providerName: string;
  displayName: string;
  tariffId: string;
  provID: number;
  code: string;
  isPVZ: boolean;
  isAddressPVZ: boolean;
}

export interface getDeliveryCompaniesResponse {
  list: DeliveryCompanyItem[];
}

export interface getPVZParams {
  lat: number;
  lon: number;
  country?: string;
  parseAddress?: boolean;
  provID?: number;
}

export interface getOrdersListParams {
  dispatchNumber?: string;
  recipientName?: string;
  address?: string;
  phone?: string;
  senderPhone?: string;
  deliveryDate: string;
  deliveryToDate: string;
  provider?: number;
  refundStatus?: number;
  statusState?: string;
  orderNumber?: string;
}

export interface getOrdersListResponse {
  deliveryList: RequestListItem[];
}

export interface getOrderLabelParams {
  number: string;
  type?: string;
}

export interface cancelOrderParams {
  userID?: string;
  number?: string;
  dispatchNumber?: string;
  serviceType?: string | null;
  type?: string;
}

// {"urlLabel":"https://api.l4y.ru/pdf/230609392638_barcode.pdf","urlLabels":["https://api.l4y.ru/pdf/230609392638_barcode.pdf"],"error":0,"fileData":null,"data":[],"errorCode":0,"errorMsg":null,"extraID":0,"extraMsg":null}
export interface getOrderLabelResponse {
  urlLabel: string;
  urlLabels: string[];
  error: number;
  fileData: null;
  data: any[];
  errorCode: number;
  errorMsg: string | null;
  extraID: number;
  extraMsg: string | null;
}

export interface PVZItem {
  lat: number;
  lon: number;
  distance: number;
  formAddress: {
    i_id: number;
    source: string;
    result: string;
    postal_code: string;
    country: string;
    country_iso_code: string;
    federal_district: string;
    region_fias_id: string;
    region_kladr_id: string;
    region_iso_code: string;
    region_with_type: string;
    region_type: string;
    region_type_full: string;
    region: string;
    area_fias_id: string;
    area_kladr_id: string;
    area_with_type: string;
    area_type: string;
    area_type_full: string;
    area: string;
    sub_area_fias_id: string;
    sub_area_kladr_id: string;
    sub_area_with_type: string;
    sub_area_type: string;
    sub_area_type_full: string;
    sub_area: string;
    city_fias_id: string;
    city_kladr_id: string;
    city_with_type: string;
    city_type: string;
    city_type_full: string;
    city: string;
    city_area: string;
    city_district_fias_id: string;
    city_district_kladr_id: string;
    city_district_with_type: string;
    city_district_type: string;
    city_district_type_full: string;
    city_district: string;
    settlement_fias_id: string;
    settlement_kladr_id: string;
    settlement_with_type: string;
    settlement_type: string;
    settlement_type_full: string;
    settlement: string;
    street_fias_id: string;
    street_kladr_id: string;
    street_with_type: string;
    street_type: string;
    street_type_full: string;
    street: string;
    house_fias_id: string;
    house_kladr_id: string;
    house_cadnum: string;
    house_with_type: string;
    house_type: string;
    house_type_full: string;
    house: string;
    block_type: string;
    block_type_full: string;
    block: string;
    entrance: string;
    floor: string;
    flat_fias_id: string;
    flat_cadnum: string;
    flat_type: string;
    flat_type_full: string;
    flat: string;
    stead_fias_id: string;
    stead_kladr_id: string;
    stead_type: string;
    stead_type_full: string;
    stead: string;
    flat_area: string;
    square_meter_price: string;
    flat_price: string;
    postal_box: string;
    fias_id: string;
    fias_code: string;
    fias_level: string;
    fias_actuality_state: string;
    kladr_id: string;
    geoname_id: string;
    capital_marker: string;
    okato: string;
    oktmo: string;
    tax_office: string;
    tax_office_legal: string;
    timezone: string;
  };
  provID: number;
  cityCode: string;
  code: string;
  name: string;
  address: string;
  multiplaceDeliveryAllowed: boolean;
  workTime: string;
}

export type getPVZResponse = PVZItem[];

interface orderGoodItem {
  height: number;
  length: number;
  width: number;
  weight: number;
}

export interface calculateOrderParams {
  addservice: any[];
  goods: orderGoodItem[];
  estimatedCost?: number;
  receiverCityFias: string;
  receiverCityKladr: string;
  receiverIndex: string;
  cityname: string;
  receiverAddress: string;
  senderIndex: string;
  provID: number;
  tariffId: string;
  dateExecute: string;
  code: string;
  countryCode: string;
  countryCode2: string;
  paymenttype: number;
  addDeliveryPrice: number;
  pvzCode?: string;
}

export interface calculateItem {
  provider: string;
  provID: string;
  providerID: number;
  priceType: number;
  receiverCityId: string;
  deliveryPeriodMin: number;
  deliveryPeriodMax: number;
  deliveryDateMin: string;
  deliveryDateMax: string;
  tariffId: string;
  isPVZ: boolean;
  priceByCurrency: number;
  priceByCurrencyPure: number;
  currency: string;
  percentVAT: number;
  error: {
    code: number;
    text: string;
  }[];
  exProviderkey: string;
  exDeliverytype: string;
  exTariffid: string;
  exAccountName: string;
  exTariffname: string;
  exProvID: number;
  isGoodsPrice: boolean;
  addDeliveryPrice: number;
  uid: string;
}

export type calculateOrderResponse = calculateItem[];

export enum CRPageMode {
  create = 'create',
  calculate = 'calculate',
}

interface CDContact {
  phone: string;
  phone1?: string;
  email?: string;
  contactName?: string;
}

interface CDAddress {
  flat: string;
  house: string;
  street: string;
  city: string;
  cityCode?: string;
  kladr: string;
  fias: string;
  postCode: string;
  pvzCode?: string;
  countyCode: string;
  countyCode2: string;
  fullAddress: string;
  isSecondIndex: boolean;
  country2: string;
  postCode2: string;
  city2?: string;
}

interface CDSeller {
  inn?: string;
  companyName: string;
  contact: CDContact;
  address: CDAddress;
}

interface CDRealSeller {
  phone: string;
  room: string;
  contactName: string;
  internalNumber?: string;
  pureInternalNumber?: string;
}

interface PackageItem {
  amount: number;
  wareKey: string;
  cost?: number;
  payment?: number;
  paymentVATRate?: string;
  paymentVATSum?: number;
  weight: number;
  comment: string;
}

interface PackageSize {
  weight: number;
  length: number;
  width: number;
  height: number;
}

interface CDPackage {
  dispatchNumber?: string;
  number?: string;
  barCode?: string;
  size: PackageSize;
  items: PackageItem[];
}

interface PersonFIO {
  f: string;
  n: string;
  s: string;
}

interface CDRecipient {
  personFIO: PersonFIO;
  address: CDAddress;
  contact: CDContact;
}

export interface createOrderParams {
  userID?: string;
  userCreator?: string;
  number?: string;
  serviceType: string;
  priceType: number;
  price: number;
  priceByCurrencyPure: number;
  paymentType: number;
  cashType: number;
  date?: string;
  estimatedCost: number;
  seller: CDSeller;
  paymenttype?: number;
  realSeller: CDRealSeller;
  packages: CDPackage[];
  recipient: CDRecipient;
  accountProviderName?: string;
  isReadOnly?: boolean;
  isPVZ: boolean;
  exTariffId: string;
  exDeliverytype: string;
  exTariffname: string;
  parcelCancel?: number;
  shipParcel?: number;
  addService?: any[];
  discount?: number;
  discountID?: number;
  marketPvzID?: number;
  comments: string;
  isExternal: boolean;
  companyCode?: string;
  resendNumber?: string;
  isDanger: boolean;
  orderNumber: string;
  exProviderkey: string;
  dispatchNumber: string;
  provider: string;
  isGoodsPrice?: boolean;
  addDeliveryPrice: number;
  calcUID: string;
}

export interface createOrderResponse {
  id: string;
  errorCode: string;
  msg: string;
  dispatchNumber: string;
  number: string;
  internalID: string;
  price: number;
}

export interface fetchRequestDetailResponse extends createOrderParams {
}

export interface fetchRequestDetailParams {
  number: string;
}