import {FC, useEffect, useMemo, useState} from 'react';
import {ColumnsType} from 'antd/es/table';
import {RequestListItem} from '@typings/pages/requestPageTypes';
import {Col, Drawer, Pagination, Row, Space, Table, Tooltip, Typography} from 'antd';
import styles from '../RequestsPage.module.scss';
import {sorterDate, sorterNumber, sorterString} from '@utils/sorters';
import {tableFilter, tableFilterWithSearch} from '@utils/filters';
import {useRequestPageData} from '@pages/RequestsPage/hooks/useRequestPageData';
import {ColumnsController} from '@components/forms/SearchForm';
import dayjs from 'dayjs';
import {DATE_FORMAT} from '@constants';
import {useAppDispatch, useAppSelector} from '@store';
import {setSelectedOrders, toggleShowHintsForOrders} from '@store/reducers/selectedOrders';
import {RequestDetail} from "@pages/RequestsPage/components/RequestDetail";

export const RequestsPageTable: FC = () => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const {RPColumns, loading, rpData, columns, setRPColumns, page, pageSize, onChangePagination, onColumnViewChange} =
    useRequestPageData();
  const showHintsForOrders = useAppSelector(state => state.selectedOrders.showHintsForOrders);
  const dispatch = useAppDispatch();
  const selectedOrders = useAppSelector(state => state.selectedOrders.data);
  const [openDrawer, setOpenDrawer] = useState(false);

  const onCheckBoxChange = (_: React.Key[], selectedRows: RequestListItem[]) => {
    showHintsForOrders && dispatch(toggleShowHintsForOrders(false));
    dispatch(setSelectedOrders(selectedRows));
  };

  const onClose = () => {
    dispatch(toggleShowHintsForOrders(false));
  };

  useEffect(() => {
    setOpenDrawer(showHintsForOrders);
  }, [showHintsForOrders]);

  return (
    <>
      <div className={styles.drawerWrapper}>
        <Drawer open={openDrawer} onClose={onClose} getContainer={false}/>
      </div>
      <div className={styles.requestTableContainer}>
        <Table
          id={'requestsTable'}
          rowKey={record => record.id.toString()}
          expandable={{
            expandRowByClick: true,
            showExpandColumn: false,
            expandedRowRender: (record) => (
              <div className={styles.orderDetailContainer}>
                <RequestDetail request={record}/>
              </div>
            ),
            expandedRowKeys: expandedRowKeys,
            onExpand: (expanded, record) => {
              setExpandedRowKeys((prev) =>
                expanded
                  ? [...prev, record.id.toString()]
                  : prev.filter((key) => key !== record.id.toString())
              );
            },
          }}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: selectedOrders?.map(({id}) => String(id)) || [],
            onChange: onCheckBoxChange,
            getCheckboxProps: (record: RequestListItem) => ({
              name: record.id.toString(),
              className: openDrawer ? styles.tableCheckbox : undefined,
            }),
            renderCell: (_: any, __: any, ___: any, originNode) =>
              openDrawer ? (
                <Tooltip color='red' placement='right' open title='Необходимо выбрать заказ'>
                  {originNode}
                </Tooltip>
              ) : (
                originNode
              ),
          }}
          size='small'
          className={styles.requestsTable}
          columns={columns}
          dataSource={rpData}
          scroll={{x: true}}
          tableLayout='fixed'
          pagination={false}
          loading={loading}
        />
        <Row className={styles.tableFooter}>
          <Col>
            <Typography.Text className={styles.paginationLabel}>Всего {rpData.length} записей</Typography.Text>
          </Col>
          <Col>
            <Space direction={'horizontal'}>
              <Pagination
                className={styles.pagination}
                size={'small'}
                defaultCurrent={page}
                defaultPageSize={pageSize}
                total={rpData.length}
                onChange={onChangePagination}
              />
              <ColumnsController
                columns={RPColumns}
                onColumnViewChange={onColumnViewChange}
                setColumns={setRPColumns}
                dragAll={true}
              />
            </Space>
          </Col>
        </Row>
      </div>
    </>
  );
};
