import dayjs from 'dayjs';

const getNestedValue = (obj: any, path: string) => {
    let value = obj;
    path.split('.').forEach((key: string) => {
        value = value?.[key];
    });
    return value;
};

const sorterByString = (a: any, b: any, key: string) => {
    const valueA = getNestedValue(a, key) || '';
    const valueB = getNestedValue(b, key) || '';
    return valueA.localeCompare(valueB);
};

export const sorterString = (key: string) => (a: any, b: any) => sorterByString(a, b, key);

const sorterByNumber = (a: any, b: any, key: string) => {
    const valueA = getNestedValue(a, key) || 0;
    const valueB = getNestedValue(b, key) || 0;
    return Number(valueA) - Number(valueB);
};

export const sorterNumber = (key: string) => (a: any, b: any) => sorterByNumber(a, b, key);

export const sorterByDate = (a: any, b: any, key: string) => {
    const valueA = getNestedValue(a, key) || '01.01.1970';
    const valueB = getNestedValue(b, key) || '01.01.1970';
    return dayjs(valueA).diff(dayjs(valueB));
};

export const sorterDate = (key: string) => (a: any, b: any) => sorterByDate(a, b, key);

export const sorterByBoolean = (a: any, b: any, key: string) => {
    const valueA = getNestedValue(a, key) || false;
    const valueB = getNestedValue(b, key) || false;
    return valueA === valueB ? 0 : valueA ? 1 : -1;
};

export const sorterBoolean = (key: string) => (a: any, b: any) => sorterByBoolean(a, b, key);
