import {API_URI, BASE_URL_SERVER, BASE_URL_SERVER_API} from '@constants';
import {RequestConfig, Response} from '@typings';
import {authFetch} from '@utils/auth/auth';

export const sendRequest = <T>(
  uri: API_URI,
  {method = 'GET', ...config}: RequestConfig,
  baseUrl = false,
  fullUry?: string
): Promise<Response<T>> => {
  const path = `${baseUrl ? BASE_URL_SERVER : BASE_URL_SERVER_API}${uri}`;

  return authFetch<T>(fullUry ?? path, {
    method,
    ...config,
  });
};
