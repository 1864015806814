import { DAVOptionType } from '@components/DaDataCountryAutoComplete';

const getOnlyStreet = (item: DAVOptionType) =>
  item?.fullValue?.data?.street_with_type || item?.fullValue?.data?.settlement_with_type || '';

const getOnlyHouse = (item: DAVOptionType) =>
  (item.fullValue?.data?.house &&
    (item.fullValue?.data?.house_type
      ? `${item.fullValue?.data?.house_type} ${item.fullValue?.data?.house}`
      : item.fullValue?.data?.house)) ||
  '';

const withHouse = (text: string, item: DAVOptionType) => (getOnlyHouse(item) ? `${text}, ${getOnlyHouse(item)}` : text);
const getOnlyField = (item: any, field: string) => item.fullValue?.data?.[field] || '';

export { getOnlyStreet, getOnlyHouse, withHouse, getOnlyField };
