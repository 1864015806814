import {ServiceFetchType} from "@typings";
import {sendRequest} from "@api";
import {API_URI, jsonTypeRequestHeaders} from "@constants";
import {
  calculateOrderParams,
  calculateOrderResponse,
  createOrderParams,
  createOrderResponse,
  getDeliveryCompaniesParams,
  getDeliveryCompaniesResponse, getOrderLabelParams,
  getOrderLabelResponse,
  getOrdersListParams,
  getOrdersListResponse,
  getPVZParams,
  getPVZResponse
} from "@typings/models/orders";
import {getSimpleDataParams, getSimpleDataResponse} from "@typings/pages/packageCheckTypes";


export const getTrackingSimple: ServiceFetchType<getSimpleDataResponse, getSimpleDataParams> = ({
    params,
}) => {
    return sendRequest<getSimpleDataResponse>(API_URI.CREATE_TRACKING_SIMPLE_INFO, {
        method: 'GET',
        params,
        // headers: jsonTypeRequestHeaders,
    });
};

export const getTrackingInfo: ServiceFetchType<getSimpleDataResponse, getSimpleDataParams> = ({
    params,
}) => {
    return sendRequest<getSimpleDataResponse>(API_URI.CREATE_TRACKING_INFO, {
        method: 'GET',
        params,
        // headers: jsonTypeRequestHeaders,
    });
};
