import { PVZItem } from '@typings/models/orders';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

interface Props {
  elementId: string | undefined;
  children: React.ReactNode;
  setBalloon: React.Dispatch<React.SetStateAction<PVZItem | undefined>>;
}

export const InfoPortal: FC<Props> = ({ children, elementId, setBalloon }) => {
  const [mount, setMount] = useState<HTMLElement>();
  const el = useMemo(() => document.createElement('div'), [elementId]);

  useEffect(() => {
    if (mount) mount.appendChild(el);
    return () => {
      if (mount) mount.removeChild(el);
    };
  }, [el, mount]);

  useEffect(() => {
    const interval = setInterval(() => {
      const element = document.getElementById(String(elementId));

      if (element) {
        setMount(prev => (prev?.id === elementId ? prev : element));
      } else {
        setBalloon(undefined);
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [elementId]);

  if (!mount) return null;
  return createPortal(children, el);
};
