import {useEffect, useState} from 'react';
import Axios, {AxiosRequestConfig} from 'axios';

import {Response} from '@typings';

type TokenType = {
  accessToken?: string;
};

const createTokenProvider = () => {
  const tokenName = 'TOKEN_AUTH';

  let _token: TokenType | null =
    JSON.parse(window.localStorage.getItem(tokenName) as string) || null;

  const getExpirationDate = (jwtToken?: string): number | null => {
    if (!jwtToken) {
      return null;
    }

    const jwt = JSON.parse(atob(jwtToken.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')));

    // multiply by 1000 to convert seconds into milliseconds
    return (jwt && jwt.exp && jwt.exp * 1000) || null;
  };

  const isExpired = (jwtToken?: string): boolean => {
    const exp = getExpirationDate(jwtToken);

    if (!exp) {
      return false;
    }

    return Date.now() > exp;
  };

  const isLoggedIn = () => {
    return !!_token;
  };

  let observers: Array<(isLogged: boolean) => void> = [];

  const subscribe = (observer: (isLogged: boolean) => void) => {
    observers.push(observer);
  };

  const unsubscribe = (observer: (isLogged: boolean) => void) => {
    observers = observers.filter((_observer) => _observer !== observer);
  };

  const notify = () => {
    const isLogged = isLoggedIn();
    observers.forEach((observer) => observer(isLogged));
  };

  const setToken = (token: typeof _token | null) => {
    if (token) {
      window.localStorage.setItem(tokenName, JSON.stringify(token));
    } else {
      window.localStorage.removeItem(tokenName);
    }

    _token = token;
    notify();
  };

  const getToken = async () => {
    if (!_token) {
      return null;
    }

    if (isExpired(_token.accessToken)) {
      logout();
    }

    return _token && _token.accessToken;
  };

  return {
    getToken,
    isLoggedIn,
    setToken,
    subscribe,
    unsubscribe,
  };
};

export const createAuthProvider: () => {
  useAuth: () => [boolean];
  authFetch: <T = any>(url: string, params?: AxiosRequestConfig) => Promise<Response<T>>;
  login: (newTokens: TokenType) => void;
  logout: () => void;
  getToken: () => Promise<string | null | undefined>;
} = () => {
  const requestStack: any = {};
  const tokenProvider = createTokenProvider();

  const login = (newTokens: TokenType) => {
    tokenProvider.setToken(newTokens);
  };

  const logout = () => {
    tokenProvider.setToken(null);
    window.location.reload();
  };

  const getToken = async () => {
    const token = await tokenProvider.getToken();
    return token;
  };

  const authFetch = async function <T = any>(
    url: string,
    {headers: headersParams, ...params}: AxiosRequestConfig = {}
  ): Promise<Response<T>> {
    requestStack[url] = ++requestStack[url] || 1;
    const requestID = requestStack[url];

    const token = await tokenProvider.getToken();

    const defaultAxiosConfig: AxiosRequestConfig = {
      responseType: 'json',
      method: 'GET',
    };

    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
      ...headersParams,
    };

    return new Promise((resolve, reject) => {
      Axios({
        url,
        ...defaultAxiosConfig,
        ...params,
        headers,
      }).then(
        (r) => {
          if (r.data?.status === 'error') reject(r.data);

          if (requestID === requestStack[url]) {
            resolve(r);
          } else {
            new Error('The current request is rotten');
          }
        },
        (error) => {
          const error_code = error.response?.status;
          if (error_code === 401) logout();
          reject(error);
        }
      ).catch((error) => {
        reject(error);
      });
    });
  };

  const useAuth = () => {
    const [isLogged, setIsLogged] = useState(tokenProvider.isLoggedIn());

    useEffect(() => {
      const listener = (newIsLogged: boolean) => {
        setIsLogged(newIsLogged);
      };

      tokenProvider.subscribe(listener);
      return () => {
        tokenProvider.unsubscribe(listener);
      };
    }, []);

    return [isLogged] as [typeof isLogged];
  };

  return {
    useAuth,
    authFetch,
    login,
    logout,
    getToken,
  };
};

export const {useAuth, authFetch, login, logout, getToken} =
  createAuthProvider();
