import React, { FC, useMemo } from 'react';
import { useRequestPageData } from '@pages/RequestsPage/hooks/useRequestPageData';
import { Button, Form, Space } from 'antd';
import { DATE_FORMAT } from '@constants';
import styles from '../RequestsPage.module.scss';
import { DateRangePicker } from '../../../components/DateRangePicker/DateRangePicker';
import dayjs from 'dayjs';
import { DeliveryTypeSelect } from '@components/DeliveryTypeSelect';
import { DownloadTableBtn } from '@pages/RequestsPage/components/DownloadTableBtn';
import { RequestListItem } from '@typings/pages/requestPageTypes';
import { useQuery } from '@utils/useQuery';

export const RequestsPageForm: FC = () => {
  const searchParams = useQuery();
  // const defaultFirstDay = dayjs(localStorage.getItem('dateReg') || '', DATE_FORMAT).isValid() ? localStorage.getItem('dateReg') : dayjs().subtract(30, 'day').format(DATE_FORMAT);
  const defaultFirstDay = dayjs().format(DATE_FORMAT);
  const { form, onChangeForm, rpData, columns } = useRequestPageData();
  const initialValues = useMemo(
    () => ({
      delivery_type: searchParams.get('delivery_type') || undefined,
      first_day: searchParams.get('first_day') || defaultFirstDay,
      last_day: searchParams.get('last_day') || defaultFirstDay,
    }),
    [],
  );

  // const onEditDates = (value: RangeValue<Dayjs>) => {
  //   console.log(value)
  //   form.setFieldsValue({
  //     first_day: value[0]?.format(DATE_FORMAT),
  //     last_day: value[1]?.format(DATE_FORMAT)
  //   })
  // }

  return (
    <Form form={form} initialValues={initialValues} onValuesChange={onChangeForm}>
      <div className={styles.formContainer}>
        <Space direction='horizontal' className={styles.formItemsContainer}>
          <Form.Item name='delivery_type'>
            <DeliveryTypeSelect className={styles.deliveryTypeSelect} placeholder='Тип отправления' allowClear />
          </Form.Item>
          <DateRangePicker
            first_day={initialValues.first_day!}
            last_day={initialValues.last_day}
            name={'full_date'}
            form={form}
            // requireBothDates={false}
            // onChange={onEditDates}
            // maxDays={30}
          />
          <Button type={'text'}>
            <DownloadTableBtn<RequestListItem> columns={columns} tableData={rpData} />
          </Button>
        </Space>
      </div>
    </Form>
  );
};
