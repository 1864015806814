import React, {useMemo} from "react";
import {Button, Checkbox, Input, Menu, Space, TableColumnType} from "antd";
import {FilterDropdownProps} from "antd/es/table/interface";
import {FilterFilled} from "@ant-design/icons";
import {Exists} from "@utils/Exists";
import dayjs from "dayjs";
import {DATE_FORMAT} from "@constants";
import {removeDuplicates} from "@utils/removeDuplicates";

export const tableFilterWithSearch = <T extends object>(
  key: string,
  data: T[],
  showSearch = true,
  isDate = false
): TableColumnType<T> => {
  const [searchText, setSearchText] = React.useState<string>('');
  const filterOptions = useMemo(() => removeDuplicates(data.flatMap((item) => {
      const value = !isDate ? item[key as keyof T] : isDate && item[key as keyof T] ? dayjs(item[key as keyof T] as string).format(DATE_FORMAT) : undefined;
      if (!value) return [];
      return {
        value: String(value),
        text: String(value),
      }
    }).filter(
      (item, index, self) => {
        // check if the item is in the search text
        const satisfiesSearch = (String(item.text as unknown as string || '').toLowerCase()).includes(searchText.toLowerCase())
        // check if the item is unique
        if (satisfiesSearch) return self.findIndex((t) => t.value === item.value) === index
        return false;
      }
    ), 'text'),
    [data, key, searchText]
  );

  const onCheckboxChange = (value: React.Key, selectedKeys: React.Key[], setSelctedKeys: (selectedKeys: React.Key[]) => void) => {
    setSelctedKeys(selectedKeys.includes(value) ? selectedKeys.filter((key) => key !== value) : [...selectedKeys, value]);
  }

  const defaultFilteredValue = filterOptions.map((item) => item.value as string);

  const checkValueIsEqual = (value: string | number | boolean | undefined | React.Key, record: T) => {
    if (isDate && value) {
      return dayjs(record[key as keyof T] as string).format(DATE_FORMAT) === value;
    } else {
      return record[key as keyof T] === value;
    }
  }

  return {
    filterIcon: () => <FilterFilled style={{color: 'rgba(0, 0, 0, 0.29)'}}/>,
    defaultFilteredValue,
    filterResetToDefaultFilteredValue: true,
    onFilter: checkValueIsEqual,
    filterDropdown: ({setSelectedKeys, selectedKeys, clearFilters, confirm}: FilterDropdownProps) => (
      <div style={{padding: 8, display: 'grid'}} onKeyDown={(e) => e.stopPropagation()}>
        <Exists visible={showSearch}>
          <Input
            placeholder={`Поиск`}
            onChange={(e) => {
              setSearchText(e.target.value);
              setSelectedKeys([]);
            }}
            style={{marginBottom: 8, display: 'block'}}
          />
        </Exists>
        <Menu>
          {filterOptions.map((item) => (
            <Menu.Item key={item.value as string}
                       onClick={() => onCheckboxChange(item.value as string, selectedKeys, setSelectedKeys)}>
              <Space>
                <Checkbox checked={selectedKeys.includes(item.value as string)}
                          onChange={() => onCheckboxChange(item.value as string, selectedKeys, setSelectedKeys)}/>
                {item.text as string}
              </Space>
            </Menu.Item>
          ))}
        </Menu>
        <Space style={{
          borderTop: '1px solid #f0f0f0',
          padding: '7px 8px',
          justifyContent: 'space-between'
        }} direction={'horizontal'}>
          <Button size={'small'} onClick={clearFilters} type={'text'}
                  disabled={selectedKeys.length === defaultFilteredValue.length}>Выбрать все</Button>
          <Button onClick={() => confirm()} type={'primary'} size={'small'}>ОК</Button>
        </Space>
      </div>
    ),
  }
};

export const tableFilter = <T extends object>(key: string, data: T[], isDate = false): TableColumnType<T> => {
  return tableFilterWithSearch<T>(key, data, false, isDate);
}