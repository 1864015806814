import {FC, useMemo} from 'react';

import {ColumnsViewControllerModal} from '@components/modals';
import {
  DefaultColumn,
  FilterListColumns,
  OnColumnViewChange,
  RenderModal,
  SetState,
} from '@typings';

import styles from './searchForm.module.scss';
import {SettingFilled} from "@ant-design/icons";
import {useAppDispatch} from "@store";
import {openModal} from "@store/reducers/modal";

interface Props {
  columns: any[];
  onColumnViewChange: OnColumnViewChange;
  setColumns: SetState<any[]>;
  dragAll?: boolean;
}

export const ColumnsController: FC<Props> = ({
                                               columns,
                                               onColumnViewChange,
                                               setColumns,
                                               dragAll,
                                             }) => {
  const dispatch = useAppDispatch();
  const dispatchShowModal = (renderModal: RenderModal) => dispatch(openModal(renderModal));

  const filterListColumns: FilterListColumns = useMemo(() => {
    return columns?.reduce((acc: FilterListColumns, column) => {
      const {checked, title, key, required} = column || {};

      if (required) {
        return acc;
      }

      const filterData = {
        isVisible: checked,
        title: title?.toString() || '',
        key: key?.toString() || '',
      };

      return [...acc, filterData];
    }, []);
  }, [columns]);

  const onModalOpen = () => {
    dispatchShowModal(renderModal(filterListColumns, onColumnViewChange, setColumns, dragAll));
    // openModal(renderModal(filterListColumns, onColumnViewChange, setColumns, dragAll));
  };

  return (
    <button type='button' className={styles.view_button} onClick={onModalOpen}>
      <SettingFilled/>
    </button>
  );
};

const renderModal = (
  filterListColumns: FilterListColumns,
  onColumnViewChange: OnColumnViewChange,
  setColumns: SetState<DefaultColumn[]>,
  dragAll?: boolean
): RenderModal => {
  return (isOpen) => {
    return (
      <ColumnsViewControllerModal
        isOpen={isOpen}
        filterListColumns={filterListColumns}
        onColumnViewChange={onColumnViewChange}
        setColumns={setColumns}
        dragAll={dragAll}
      />
    );
  };
};
