import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {PVZItem} from "@typings/models/orders";

// Define a type for the slice state
interface State {
  data: PVZItem[] | null;
}

// Define the initial state using that type
const initialState: State = {
  data: null,
}

export const availablePVZ = createSlice({
  name: 'availablePVZ',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setAvailablePVZ: (state, action: PayloadAction<State>) => {
      state.data = action.payload.data;
    },
  }
})

export const {
  setAvailablePVZ,
} = availablePVZ.actions

export default availablePVZ.reducer