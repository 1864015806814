import React, { FC, ReactNode } from 'react';
import { Tooltip } from 'antd';
import { TooltipHints } from '@utils/tooltipHints';

interface TooltipHintProps {
  k: string;
  children: ReactNode;
}

export const BtnHint: FC<TooltipHintProps> = ({ k, children }) => {
  const hint = TooltipHints.find(hint => hint.key === k);

  return hint?.text ? (
    <Tooltip title={hint?.text}>
      {children}
    </Tooltip>
  ) : <>{children}</>;
};