import modal from "./modal";
import deliveryTypes from "./deliveryTypes";
import selectedOrders from "./selectedOrders";
import availablePVZ from "./availablePVZ";

export default {
  modal,
  deliveryTypes,
  selectedOrders,
  availablePVZ
}