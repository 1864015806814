import {ModalProps} from "antd";
import {FC} from "react";
import Modal from "antd/lib/modal/Modal";
import styles from './DeleteConfirmationModal.module.scss';

interface DeleteConfirmationModalProps extends ModalProps {
}
export const DeleteConfirmationModal: FC<DeleteConfirmationModalProps> = ({...props}) => {
  return (
    <Modal
      className={styles.DeleteConfirmationModal}
      title='Вы действительно хотите отменить заказ (ы)?'
      // visible={open}
      // onOk={onOk}
      // onCancel={onCancel}
      okText={'Да'}
      cancelText={'Нет'}
      okButtonProps={{className: styles.okBtn}}
      cancelButtonProps={{className: styles.cancelBtn}}
      {...props}
    >
    </Modal>
  )
}