interface Hint {
  key: string;
  text: string;
}

export const TooltipHints: Hint[] = [
  {
    key: 'request.list',
    text: 'Страница список заявок',
  },
  {
    key: 'request.create',
    text: 'Страница создания заявок',
  },
  {
    key: 'request.calculator',
    text: 'Калькулятор',
  },
  {
    key: 'request.map',
    text: 'Карта',
  },
  {
    key: 'request.printLabels',
    text: 'Печать этикеток',
  },
  {
    key: 'request.printInvoices',
    text: 'Печать накладных',
  },
  {
    key: 'request.settings',
    text: 'Настройки личного кабинета',
  },
  {
    key: 'request.create.calculate',
    text: 'Рассчитать стоимости',
  },
  {
    key: 'request.create.btn',
    text: 'Создать заявку',
  },
  {
    key: 'request.to_create.btn',
    text: 'Перейти на страницу создания заказа',
  },
  {
    text: 'Отслеживание отправления',
    key: 'request.tracking',
  },
  {
    text: 'Отменить заказ',
    key: 'request.cancelOrder',
  },
  {
    text: 'Контакты курьера',
    key: 'request.callCourier',
  },
];
