import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormInstance } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { RequestListItem, RequestPageForm, RPColumnsConfig } from '@typings/pages/requestPageTypes';
import { FilterColumn, OnColumnViewChange } from '@typings/hooks';
import { getOrdersList } from '@api/services/orders';
import { CD_DATE_FORMAT, DATE_FORMAT } from '@constants';
import { DeliveryCompanyItem } from '@typings/models/orders';
import { RootState, useAppSelector } from '@store';
import { ColumnsType } from 'antd/es/table';
import { sorterDate, sorterNumber, sorterString } from '@utils/sorters';
import { tableFilter, tableFilterWithSearch } from '@utils/filters';
import styles from '@pages/RequestsPage/RequestsPage.module.scss';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import {formatToMoney} from "@utils/formatToMoney";

type RPColumn = FilterColumn & { checked: boolean };

interface RPContextType {
  form: FormInstance<RequestPageForm>;
  // setCheckedWorkers: React.Dispatch<React.SetStateAction<number[]>>;
  RPColumns: RPColumn[];
  setRPColumns: React.Dispatch<React.SetStateAction<RPColumn[]>>;
  onColumnViewChange: OnColumnViewChange;
  page: number;
  pageSize: number;
  onChangePagination: (page: number, pageSize: number) => void;
  rpData: RequestListItem[];
  fetchData: () => void;
  loading: boolean;
  onChangeForm: (changedValues?: Partial<RequestPageForm>) => void;
  columns: ColumnsType<RequestListItem>;
}

const RPContext = createContext<RPContextType | undefined>(undefined);

export const useRequestPageData = () => {
  const context = useContext(RPContext);
  if (!context) {
    throw new Error('useRequestPageData must be used within a RequestPageDataProvider');
  }
  return context;
};

interface RequestPageDataProviderProps {
  children: ReactNode;
}

const PAGE_SIZE_DEFAULT = 50;

export const RequestPageDataProvider: FC<RequestPageDataProviderProps> = ({ children }) => {
  const refreshDataTrigger = useAppSelector(state => state.selectedOrders.refreshDataTrigger);
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = useForm<RequestPageForm>();
  const [RPColumns, setRPColumns] = useState<RPContextType['RPColumns']>(
    RPColumnsConfig.map(column => ({
      ...column,
      isVisible: true,
      checked: true,
    })),
  );
  const [rpData, setRPData] = useState<RequestListItem[]>([]);
  const [{ page, pageSize }, setPagination] = useState<{
    page: number;
    pageSize: number;
  }>({ page: 1, pageSize: PAGE_SIZE_DEFAULT });
  const { data: deliveryTypesList } = useAppSelector((state: RootState) => state.deliveryTypes);

  const onChangePagination = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const onColumnViewChange: OnColumnViewChange = useCallback(
    (key, isVisible) => {
      setRPColumns(prev => {
        return prev?.map(column => {
          if (column.key === key) {
            return { ...column, checked: isVisible, isVisible };
          }

          return column;
        });
      });
    },
    [setRPColumns, RPColumns],
  );

  const getProvider = (values: RequestPageForm, deliveryTypesList: DeliveryCompanyItem[] | null) => {
    return deliveryTypesList?.find(item => item.providerName === values.delivery_type);
  };

  const fetchData = useCallback(() => {
    setLoading(true);
    const values = form.getFieldsValue();
    history.push({
      search: `?first_day=${values.full_date[0].format(DATE_FORMAT)}&last_day=${values.full_date[1].format(
        DATE_FORMAT,
      )}&delivery_type=${values.delivery_type || ''}`,
    });
    getOrdersList({
      params: {
        deliveryDate: values.full_date[0].format(CD_DATE_FORMAT),
        deliveryToDate: values.full_date[1].format(CD_DATE_FORMAT),
        provider: getProvider(values, deliveryTypesList)?.provID || undefined,
      },
    }).then(response => {
      setRPData(response.data.deliveryList);
      setLoading(false);
    });
  }, [deliveryTypesList]);

  const columnsOrder = useMemo(
    () => RPColumns.filter(column => column.isVisible).map(column => column.key),
    [RPColumns],
  );

  let columns: ColumnsType<RequestListItem> = [
    {
      title: 'Транспортная компания',
      dataIndex: 'strProvider',
      key: 'strProvider',
      sorter: sorterString('strProvider'),
      ...tableFilter<RequestListItem>('strProvider', rpData),
    },
    {
      title: '№ заказа',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      sorter: sorterString('orderNumber'),
      ...tableFilter<RequestListItem>('orderNumber', rpData),
    },
    {
      title: 'Трек номер ТК',
      dataIndex: 'dispatchNumber',
      key: 'dispatchNumber',
      render: (text: string, { number }) => (
        <a target={'_blank'} rel={'noreferrer'} href={`/tracking?id=${number}`}>
          {text}
        </a>
      ),
      sorter: sorterNumber('number'),
      ...tableFilter<RequestListItem>('number', rpData),
    },
    {
      title: 'Получатель',
      dataIndex: 'fio',
      key: 'fio',
      sorter: sorterString('fio'),
      ...tableFilter<RequestListItem>('fio', rpData),
    },
    {
      title: 'Телефон получателя',
      dataIndex: 'recipientPhone',
      key: 'recipientPhone',
      sorter: sorterString('recipientPhone'),
      ...tableFilter<RequestListItem>('recipientPhone', rpData),
    },
    {
      title: 'Адрес',
      dataIndex: 'fullAddress',
      key: 'fullAddress',
      sorter: sorterString('fullAddress'),
      ...tableFilterWithSearch<RequestListItem>('fullAddress', rpData),
    },
    {
      title: 'Налож. платеж',
      dataIndex: 'cashType',
      key: 'cashType',
      sorter: sorterNumber('cashType'),
      ...tableFilter<RequestListItem>('cashType', rpData),
    },
    {
      title: 'Тариф',
      dataIndex: 'price',
      key: 'price',
      className: styles.numberCell,
      sorter: sorterNumber('price'),
      render: (value) => formatToMoney(Number(value)/100),
      ...tableFilter<RequestListItem>('price', rpData),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      sorter: sorterString('status'),
      render: (value, {parcelCancel}) => parcelCancel === 1 ? 'Отменён' : value,
      ...tableFilter<RequestListItem>('status', rpData),
    },
  ];

  columns = columns.filter(column => columnsOrder?.includes(column.key as string));
  columns.sort((a, b) => (columnsOrder?.indexOf(a.key as string) || 0) - (columnsOrder?.indexOf(b.key as string) || 0));

  columns.unshift({
    title: 'Дата',
    dataIndex: 'createDate',
    key: 'createDate',
    sorter: sorterDate('createDate'),
    render: (text: string) => dayjs(text).format(DATE_FORMAT),
    ...tableFilter<RequestListItem>('createDate', rpData, true),
  });

  const onChangeForm = useCallback(() => {
    fetchData();
  }, [form, deliveryTypesList]);

  useEffect(() => {
    fetchData();
  }, [refreshDataTrigger]);

  return (
    <RPContext.Provider
      value={{
        form,
        RPColumns,
        setRPColumns,
        onColumnViewChange,
        page,
        pageSize,
        onChangePagination,
        rpData,
        fetchData,
        loading,
        onChangeForm,
        columns,
      }}
    >
      {children}
    </RPContext.Provider>
  );
};
