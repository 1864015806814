import {FC} from "react";
import {usePackageCheckData} from "@pages/PackageCheckPage/hooks/usePackageCheckData";
import {List} from "antd";
import styles from '../PackageCheckPage.module.scss'
import clsx from "clsx";
import {PackageItem} from "@typings/pages/packageCheckTypes";

interface PCListItemProps {
  item: PackageItem;
  isHeader?: boolean;
}

export const PCListItem: FC<PCListItemProps> = ({item, isHeader}) => {
  const {
    selectedPackage,
    setSelectedPackage
  } = usePackageCheckData();
  return (
    <List.Item onClick={() => setSelectedPackage(item)}
               className={selectedPackage?.id === item.id && !isHeader ? clsx(styles.pcListItem, styles.selected) : styles.pcListItem}>
      <List.Item.Meta
        title={item.title}
        description={(
          <div className={styles.description}>
            <div>{item.status}</div>
            <div>{item.id}</div>
          </div>
        )}
        className={styles.pcListItemContent}
      />
    </List.Item>
  )
}

export const PackageList: FC = () => {
  const {
    PCdata
  } = usePackageCheckData();
  return (
    <List
      size="small"
      className={styles.pcList}
      itemLayout="horizontal"
      dataSource={PCdata}
      renderItem={(item) => (
        <PCListItem item={item}/>
      )}
    />
  )
}