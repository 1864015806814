// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_dateRangeInput__MTWTI .ant-picker-input > input {
  width: 72px;
}
.styles_dateRangeInput__MTWTI .ant-picker-active-bar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/DateRangePicker/styles.module.scss"],"names":[],"mappings":"AAEY;EACI,WAAA;AADhB;AAIY;EACI,aAAA;AAFhB","sourcesContent":[".dateRangeInput {\n        :global {\n            .ant-picker-input > input {\n                width: 72px;\n            }\n\n            .ant-picker-active-bar {\n                display: none;\n            }\n        }\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateRangeInput": `styles_dateRangeInput__MTWTI`
};
export default ___CSS_LOADER_EXPORT___;
