import {BreadcrumbProps} from "antd/es/breadcrumb/Breadcrumb";

interface PageLocationConfig {
  [path: string]: {
    name: string;
    children: {
      name: string;
      path: string;
    }[];
  };
}

export const pageLocations: PageLocationConfig = {
  '/request-list': {
    name: 'Список заказов',
    children: [
      {
        name: 'Создать заказ',
        path: '/create-request',
      },
      {
        name: 'Калькулятор',
        path: '/calculate',
      },
      {
        name: 'Отслеживание отправления',
        path: '/tracking',
      },
      {
        name: 'Список заказов',
        path: '/request-list'
      },
      {
        name: 'Карта',
        path: '/map'
      },
      {
        name: 'Инфо',
        path: '/text'
      },
    ],
  }
}

export const getNestedPagePath = (path: string): BreadcrumbProps['items'] => {
  return Object.keys(pageLocations).flatMap((pageLocation) => {
    const parentPath = {
      href: pageLocation,
      title: pageLocations[pageLocation].name
    }
    if (pageLocation === path) {
      return [parentPath]
    }
    return pageLocations[pageLocation].children.flatMap((child) => {
      if (child.path === path) {
        return [parentPath, {
          href: child.path,
          title: child.name,
        }]
      }
      return [];
    });
  });
}