import {FC} from "react";
import {RequestPageDataProvider} from "@pages/RequestsPage/hooks/useRequestPageData";
import {Space} from "antd";
import {RequestsPageForm} from "@pages/RequestsPage/components/RequestsPageForm";
import styles from './RequestsPage.module.scss'
import {RequestsPageTable} from "@pages/RequestsPage/components/RequestsPageTable";

export const RequestsPage: FC = () => {
  return (
    <RequestPageDataProvider>
      <Space direction='vertical' className={styles.requestPageContainer}>
        <RequestsPageForm />
        <RequestsPageTable />
      </Space>
    </RequestPageDataProvider>
  )
}