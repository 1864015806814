import { FC, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {getNestedPagePath} from "../constants/pages";

interface Props {
    children: ReactNode;
}

const getAddTitle = (pathname: string) => {
  const pathList = getNestedPagePath(pathname);
  return ' | ' + (pathList?.[pathList?.length - 1]?.title || '');
};

export const SetTitleProvider: FC<Props> = ({ children }) => {
    const { pathname } = useLocation();

    useEffect(() => {
        const baseTitle = 'Fantom';

        document.title = baseTitle + getAddTitle(pathname);
    }, [pathname]);

    return <>{children}</>;
};
