import { FC, memo, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import {
  YMaps,
  Map,
  Placemark,
  ZoomControl,
  FullscreenControl,
  ObjectManager,
  Circle,
  Clusterer,
} from '@pbe/react-yandex-maps';
import styles from '../RequestsPage.module.scss';
import { getPVZList } from '@api/services/orders';
import { Each } from '@utils/Each';
import { PVZItem } from '@typings/models/orders';
import { getAddressDataByCoordinates } from '@api/services/dadata';
import { isEqual } from '@utils/isEqual';
import { Exists } from '@utils/Exists';
import { InfoPortal } from '@pages/CreateRequestPage/components/InfoPortal';
import { useAppDispatch } from '@store';
import { setAvailablePVZ } from '@store/reducers/availablePVZ';
import { Button, Drawer, Form, FormInstance, Switch } from 'antd';
import {CreateRequestFormData, getPVZProvId} from '@typings/pages/createRequestTypes';
import FullscreenControlComponent from './FullscreenControlComponent';
import MenuComponent from './MenuComponent';

export const crMapDefaultCenter: [number, number] = [55.751574, 37.573856];

interface Props {
  className?: string;
  providerId?: number;
  zoom?: number;
  country?: string;
  centerPoint?: [number, number];
  search?: string;
  onPlaceMarkClick?: (data: PVZItem) => void;
  form?: FormInstance<CreateRequestFormData>;
}

interface IBalloonWithInfoProps {
  setBalloon: React.Dispatch<React.SetStateAction<PVZItem | undefined>>;
  visibleBalloon: { id: string; data: any } | undefined;
  onConfirmPlaceMarkClick: () => void;
}

const BalloonWithInfo: FC<IBalloonWithInfoProps> = memo(
  ({ visibleBalloon, setBalloon, onConfirmPlaceMarkClick }) => (
    <Exists visible={!!visibleBalloon}>
      <InfoPortal elementId={visibleBalloon?.id} setBalloon={setBalloon}>
        <div>
          <div>Адрес: {visibleBalloon?.data.address}</div>
          <div>Время работы: {visibleBalloon?.data.workTime}</div>

          <Button onClick={onConfirmPlaceMarkClick}>Выбрать</Button>
        </div>
      </InfoPortal>
    </Exists>
  ),
  isEqual,
);

const CRMap: FC<Props> = ({
  className,
  onPlaceMarkClick,
  providerId,
  country,
  search,
  centerPoint: center,
  zoom: _zoom = 10,
  form,
}) => {
  const [zoom, setZoom] = useState<number>(_zoom);
  const [centerPoint, setCenterPoint] = useState<[number, number] | null>(null);
  const [placeMarks, setPlaceMarks] = useState<any[]>([]);
  const [storedPlaceMarks, setStoredPlaceMarks] = useState<any[]>([]);
  const [balloon, setBalloon] = useState<PVZItem>();

  useEffect(() => {
    setZoom(_zoom || 10);
  }, [_zoom]);

  const visibleBalloon = useMemo(
    () =>
      balloon
        ? {
            id: `${balloon.lat};${balloon.lon}`,
            data: balloon,
          }
        : undefined,
    [balloon],
  );

  const onConfirmPlaceMarkClick = useCallback(() => {
    if (balloon) {
      onPlaceMarkClick?.(balloon);
      // TODO: add close hint
      // через parentNode можно наверняка добраться до иконки закрытия и закрыть
      // const hint = document.getElementById(String(visibleBalloon?.id));
      // console.log(hint);
    }
  }, [balloon, onPlaceMarkClick]);

  const dispatch = useAppDispatch();

  const tryToSetCenterPoint = () => {
    const successCallback = (position: GeolocationPosition) => {
      setCenterPoint([position.coords.latitude, position.coords.longitude]);
    };

    const errorCallback = () => {
      setCenterPoint(center || crMapDefaultCenter);
    };

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  };

  useEffect(() => {
    tryToSetCenterPoint();
  }, []);

  useEffect(() => {
    if (center && JSON.stringify(center) !== JSON.stringify(centerPoint)) {
      setCenterPoint(null);
      setTimeout(() => setCenterPoint(center), 0);
    }
  }, [center]);

  const getPlaceMarks = (countryCode: string) => {
    if (!centerPoint) return;
    getPVZList({
      params: {
        lat: centerPoint?.[0],
        lon: centerPoint?.[1],
        parseAddress: true,
        country: countryCode || 'RU',
        provID: providerId || getPVZProvId.empty,
      },
    }).then(response => {
      dispatch(setAvailablePVZ({ data: response.data }));

      const newPlaceMarks = response.data.flatMap((item, idx) => ({
        geometry: { type: 'Point', coordinates: [item.lat, item.lon] },
        properties: {
          hintContent: item.address || '',
          balloonContent: `<div id="${item.lat};${item.lon}" class="pvz-card"></div>`,

          // добавлял для дробления кластера на секторы
          // clusterCaption: idx % 2 === 0 ? 'Один' : 'Два',
          // iconCaption: idx % 2 === 0 ? 'Один' : 'Два',
        },
        data: item,
        workTime: item.workTime,
        id: `${item.lat};${item.lon}`,
        // добавлял для дробления кластера на секторы
        // type: 'Feature',
        options: {
          // preset: 'default#darkgreenClusterIcons',
          // добавлял для дробления кластера на секторы
          // preset: idx % 2 === 0 ? 'islands#redStretchyIcon' : 'islands#darkgreenStretchyIcon',
          // iconColor: idx % 2 === 0 ? 'red' : 'darkgreen',
        },
      }));
      setPlaceMarks(newPlaceMarks);
      setStoredPlaceMarks(newPlaceMarks);
    });
  };

  useEffect(() => {
    if (centerPoint && !country) {
      getAddressDataByCoordinates({
        params: {
          coordinates: centerPoint,
        },
      }).then(response => {
        getPlaceMarks(response.data?.suggestions?.[0]?.data?.country_iso_code || 'RU');
      });
    } else if (centerPoint) {
      getPlaceMarks(country || 'RU');
    }
  }, [centerPoint, providerId, country]);

  useEffect(() => {
    if (search && placeMarks.length) {
      setPlaceMarks(storedPlaceMarks.filter(item => JSON.stringify(item).toLowerCase().includes(search.toLowerCase())));
    } else if (!search) {
      setPlaceMarks(storedPlaceMarks);
    }
  }, [search]);

  const ref = useRef<HTMLDivElement>(null);

  const [map, setMap] = useState<{
    width: string;
    height: string;
  } | null>(null);

  // const objectManager = useRef<any>(null);

  // отрисовка кластеров с дроблением по цветам
  // useEffect(() => {
  //   if (objectManager.current) {
  //     // objectManager.current.clusters.options.set({
  //     //   clusterIconLayout: 'default#pieChart',
  //     //   // Радиус диаграммы в пикселях.
  //     //   clusterIconPieChartRadius: 15,
  //     //   // Радиус центральной части макета.
  //     //   clusterIconPieChartCoreRadius: 10,
  //     //   // Ширина линий-разделителей секторов и внешней обводки диаграммы.
  //     //   clusterIconPieChartStrokeWidth: 1,
  //     //   // Определяет наличие поля balloon.
  //     //   // hasBalloon: false,
  //     // });

  //     objectManager.current.add({
  //       type: 'FeatureCollection',
  //       features: placeMarks,
  //     });

  //     // @ts-ignore
  //     objectManager.current.objects.events.add('click', function (e) {
  //       var objectId = e.get('objectId');

  //       const item = placeMarks.find(item => item.id === objectId);

  //       if (item) {
  //         setBalloon(item.data);
  //       }
  //     });
  //   }
  // }, [placeMarks]);

  const selectedPointCoords = centerPoint?.join(';');

  const [openMap, setOpenMap] = useState(false);

  return (
    <div ref={ref} className={`${styles.mapComponentWrapper} ${className}`}>
      {/* <MenuComponent openMap={openMap} /> */}
      {centerPoint && (
        <YMaps>
          <Map
            style={map ? map : {}}
            className={`${styles.mapComponent} ${className}`}
            defaultState={{
              center: centerPoint,
              zoom,
            }}
            modules={[
              // 'control.ZoomControl',
              'control.FullscreenControl',
              'geoObject.addon.balloon',
              'geoObject.addon.hint',
              'objectManager.addon.clustersBalloon',
              'objectManager.addon.objectsBalloon',
              'objectManager.addon.objectsHint',
              'layout.PieChart',
            ]}
            onMouseDown={(e: any) => {
              if (e?._sourceEvent?.originalEvent?.coords) {
                const coords = e._sourceEvent.originalEvent.coords;

                setCenterPoint(coords);
              }
            }}
          >
            <FullscreenControlComponent setOpenMap={setOpenMap} ref={ref} setMap={setMap} />
            <ZoomControl options={{ position: { right: 10, top: 50 } }} />
            {/* красная метка https://reactjsexample.com/yandex-maps-api-bindings-for-react/ */}

            <Clusterer
              options={{
                type: 'FeatureCollection',
                // preset: 'islands#darkgreenClusterIcons',
                groupByCoordinates: false,
                // clusterIconLayout: 'islands#pieChart',
              }}
              type='FeatureCollection'
            >
              {[
                ...placeMarks,
                // заготовка на выставление своей точки
                // ...(selectedPointCoords !== crMapDefaultCenter.join(';')
                //   ? [
                //       {
                //         options: { iconColor: 'red' },
                //         geometry: { type: 'Point', coordinates: centerPoint },
                //         data: {
                //           lat: centerPoint[0],
                //           lon: centerPoint[1],
                //         },
                //       },
                //     ]
                //   : []),
              ].map(item => {
                const key = `${item.data.lat};${item.data.lon}`;

                return (
                  <Placemark
                    key={key}
                    onClick={() => {
                      setTimeout(() => setBalloon(item.data), 0);
                    }}
                    geometry={item.geometry}
                    properties={item.properties}
                    options={{
                      ...item.options,
                      ...(selectedPointCoords === key ? { iconColor: 'red' } : {}),
                    }}
                    // options: {
                    //   // preset: 'default#darkgreenClusterIcons',
                    //   // добавлял для дробления кластера на секторы
                    //   // preset: idx % 2 === 0 ? 'islands#redStretchyIcon' : 'islands#darkgreenStretchyIcon',
                    //   // iconColor: idx % 2 === 0 ? 'red' : 'darkgreen',
                    // },
                  />
                );
              })}
            </Clusterer>
            {/* <ObjectManager
              instanceRef={objectManager}
              options={{
                clusterize: true,
                // ObjectManager принимает те же опции, что и кластеризатор.
                gridSize: 64,
                // Макет метки кластера pieChart.
                // clusterIconLayout: 'islands#pieChart',
                groupByCoordinates: false,
                // clusterIconLayout: 'default',
              }}
              objects={{
                openBalloonOnClick: true,
                // preset: 'islands#greenDotIcon',
              }}
              clusters={{
                // preset: 'islands#redClusterIcons',
                type: 'FeatureCollection',
                clusterIconLayout: 'default#pieChart',
              }}
              // // @ts-ignore
              // filter={(object, idx) => {
              //   console.log(object);

              //   return true;
              // }}
              // defaultFeatures={placeMarks}
              modules={[
                'objectManager.addon.clustersBalloon',
                'objectManager.addon.objectsBalloon',
                'objectManager.addon.objectsHint',
              ]}
            /> */}
          </Map>
          <BalloonWithInfo
            onConfirmPlaceMarkClick={onConfirmPlaceMarkClick}
            setBalloon={setBalloon}
            visibleBalloon={visibleBalloon}
          />
        </YMaps>
      )}
    </div>
  );
};

export default memo(CRMap, isEqual);
