// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MapPage_formContainer__lbnQ7 {
  width: 100%;
  padding-inline: 15px;
  padding-bottom: 10px;
}
.MapPage_formContainer__lbnQ7 .MapPage_island__n5pkn {
  background: #c8e0ca;
  border-radius: 10px;
  padding: 20px 10px;
}
.MapPage_formContainer__lbnQ7 .MapPage_mapContainer__KqQFK {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  min-height: 500px;
  width: 100%;
  gap: 8px;
}
.MapPage_formContainer__lbnQ7 .MapPage_mapContainer__KqQFK .MapPage_map__E5Hl5 {
  min-height: 400px;
  height: 70vh;
}`, "",{"version":3,"sources":["webpack://./src/pages/MapPage/MapPage.module.scss","webpack://./src/styles/colors.module.scss"],"names":[],"mappings":"AAEA;EACI,WAAA;EACA,oBAAA;EACA,oBAAA;AADJ;AAGI;EACI,mBCPC;EDQD,mBAAA;EACA,kBAAA;AADR;AAII;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;EACA,QAAA;AAFR;AAGQ;EACI,iBAAA;EACA,YAAA;AADZ","sourcesContent":["@import \"src/styles/colors.module\";\n\n.formContainer {\n    width: 100%;\n    padding-inline: 15px;\n    padding-bottom: 10px;\n\n    .island {\n        background: $island;\n        border-radius: 10px;\n        padding: 20px 10px;\n    }\n\n    .mapContainer {\n        display: flex;\n        flex-direction: column;\n        min-width: 350px;\n        min-height: 500px;\n        width: 100%;\n        gap: 8px;\n        .map {\n            min-height: 400px;\n            height: 70vh;\n        }\n    }\n}","$balance: #1cd900;\n$island: #c8e0ca;\n$border: rgba(5, 5, 5, 0.06);\n$selected: #f8bc48;\n$dot: rgba(0, 0, 0, 0.25);\n$primary: #f8bc48;\n$greenGradient: linear-gradient(0.5turn, rgb(128, 186, 67), rgb(121, 198, 69), rgb(104 157 65));\n\n// закоментить или удалить нижнюю\n// $island: #ccc;\n// $primary: #ccc;\n// $balance: #ccc;\n// $selected: #ccc;\n// $greenGradient: #ccc;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": `MapPage_formContainer__lbnQ7`,
	"island": `MapPage_island__n5pkn`,
	"mapContainer": `MapPage_mapContainer__KqQFK`,
	"map": `MapPage_map__E5Hl5`
};
export default ___CSS_LOADER_EXPORT___;
