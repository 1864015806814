import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { FormInstance, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { CreateRequestFormData, deliveryToTypes } from '@typings/pages/createRequestTypes';
import { RootState, useAppSelector } from '@store';
import { CRPageMode, PVZItem } from '@typings/models/orders';
import { calculateOrder, createOrder } from '@api/services/orders';
import { showError } from '@utils/show_error';
import { useLocation } from 'react-router-dom';
import { renderCalculateCostParams, renderCreateDeliveryParams } from '@pages/CreateRequestPage/utils/serialize';
import { crMapDefaultCenter } from '@pages/CreateRequestPage/components/CRMap';
import { getOnlyStreet } from '../components/utils';

interface MapOptions {
  providerId: number;
  countryCode: string;
  zoom: number;
  centerPoint?: [number, number];
  delivery_to_type?: keyof typeof deliveryToTypes;
}

interface PreValue {
  item: any;
  text?: string;
  withStreet?: boolean;
  showAll?: boolean;
}

interface CRContextType {
  form: FormInstance<CreateRequestFormData>;
  onChangeForm: (changedValues?: Partial<CreateRequestFormData>) => void;
  mapOptions: MapOptions;
  onPlaceMarkClick: (data: PVZItem) => void;
  onCalculate: () => void;
  pageMode: CRPageMode;
  onCreateDelivery: () => void;
  deliveryCostCalculated: boolean;
  packageCount: number;
  setPackageCount: React.Dispatch<React.SetStateAction<number>>;
  streetPreValue: string;
  setStreetPreValue: React.Dispatch<React.SetStateAction<string>>;
  housePreValue: string;
  setHousePreValue: React.Dispatch<React.SetStateAction<string>>;
  cityIndex?: string;
  setCityIndex: React.Dispatch<React.SetStateAction<string | undefined>>;
  checkAddressWithPVS: boolean;
  setCheckAddressWithPVS: React.Dispatch<React.SetStateAction<boolean>>;
  getPreValue: (props: PreValue) => string;
}

const CRContext = createContext<CRContextType | undefined>(undefined);

export const useCRequest = () => {
  const context = useContext(CRContext);
  if (!context) {
    throw new Error('useCRequest must be used within a CreateRequestProvider');
  }
  return context;
};

interface CreateRequestProviderProps {
  children: ReactNode;
}

interface HistoryLocationState extends CreateRequestFormData {
  packageCount: number;
  streetPreValue: string;
  housePreValue: string;
  providerId: number;
  centerPoint?: [number, number];
}

export const CreateRequestProvider: FC<CreateRequestProviderProps> = ({ children }) => {
  const { pathname, state: LocationState } = useLocation<HistoryLocationState>();
  const [streetPreValue, setStreetPreValue] = useState<string>(LocationState?.streetPreValue || '');
  const [housePreValue, setHousePreValue] = useState<string>(LocationState?.housePreValue || '');
  const [cityIndex, setCityIndex] = useState<string | undefined>(undefined);
  const [checkAddressWithPVS, setCheckAddressWithPVS] = useState(false);

  const [packageCount, setPackageCount] = useState(LocationState?.packageCount || 1);
  const [deliveryCostCalculated, setDeliveryCostCalculated] = useState<boolean>(false);
  const pageMode = pathname.includes('calculate') ? CRPageMode.calculate : CRPageMode.create;
  const [form] = useForm<CreateRequestFormData>();
  const [mapOptions, setMapOptions] = useState<MapOptions>({
    centerPoint: LocationState?.centerPoint || undefined,
    providerId: LocationState?.providerId || 0,
    countryCode: 'RU',
    delivery_to_type: LocationState?.delivery_to_type || undefined,
    zoom: 10,
  });
  const { data: deliveryTypesList } = useAppSelector((state: RootState) => state.deliveryTypes);

  const has = ({ text, text2, showAll }: Pick<PreValue, 'text' | 'showAll'> & { text2?: string | null }) =>
    (showAll || text?.includes(text2 || '')) && text2;

  const getPreValue = (props: PreValue) => {
    const { item, withStreet, showAll } = props;

    return [
      has({ ...props, text2: item?.fullValue?.data?.region_with_type }),
      has({ ...props, text2: item?.fullValue?.data?.city_with_type }),
      has({ ...props, text2: item?.fullValue?.data?.area_with_type }),
      (showAll || withStreet) &&
        has({
          ...props,
          text2: getOnlyStreet(item),
        }),
    ]
      .reduce((prev: (string | null | undefined | false)[], item) => {
        if (prev.includes(item)) return prev;

        return [...prev, item];
      }, [])
      .filter(i => i)
      .join(', ');
  };

  const onChangeForm = useCallback(
    (changedValues?: Partial<CreateRequestFormData>) => {
      const values = form.getFieldsValue();
      const newMapOptions = { ...mapOptions };
      if (values.delivery_type) {
        const provider = deliveryTypesList?.find(item => item.providerName === values.delivery_type);
        newMapOptions.providerId = provider?.provID || 0;
      }
      if (values.country) {
        newMapOptions.countryCode = values.country.data.alfa2;
      }

      if (values.city) {
        const geo_lat = values?.city?.data?.geo_lat;
        const geo_lon = values?.city?.data?.geo_lon;

        const street_with_type = values?.city?.data?.street_with_type || values?.city?.data?.settlement_with_type;
        const house = values?.city?.data?.house || values?.house;
        const flat = values?.city?.data?.flat || values?.apartment;

        if (geo_lat && geo_lon) {
          newMapOptions.zoom = 10;

          if (street_with_type) {
            newMapOptions.zoom = 16;
          }

          if (house) {
            newMapOptions.zoom = 18;
          }

          if (flat) {
            newMapOptions.zoom = 18;
          }
        } else {
          newMapOptions.zoom = 10;
        }

        if (geo_lat && geo_lon) newMapOptions.centerPoint = [Number(geo_lat), Number(geo_lon)];
        else {
          newMapOptions.centerPoint = crMapDefaultCenter;
          if (
            JSON.stringify(newMapOptions.centerPoint) !== JSON.stringify(crMapDefaultCenter) &&
            newMapOptions.centerPoint !== undefined
          ) {
            showError(`Не удалось определить координаты для ${values.city.value}`);
          }
        }
      }
      if (values.delivery_to_type) newMapOptions.delivery_to_type = values.delivery_to_type;
      if (changedValues?.sender_pays && values.recipient_pays) form.setFieldsValue({ recipient_pays: false });
      if (changedValues?.recipient_pays && values.sender_pays) form.setFieldsValue({ sender_pays: false });

      setMapOptions(newMapOptions);
    },
    [deliveryTypesList],
  );

  const onPlaceMarkClick = (data: PVZItem) => {
    const cityVisible = data.formAddress.city_with_type || data.formAddress.region_with_type;
    const street = data.formAddress.street_with_type || data.formAddress.settlement_with_type;
    const house = data.formAddress.house_type
      ? `${data.formAddress.house_type} ${data.formAddress.house}`
      : data.formAddress.house;

    form.setFieldsValue({
      delivery_to_type: 'pvz',
      cityVisible,
      visible_full_address: [cityVisible, street, house].join(', '),
      countryVisible: data.formAddress.country,
      country: {
        data: {
          alfa2: data.formAddress.country_iso_code,
          code: data.formAddress.country_iso_code,
        },
      },
      city: {
        data: { ...data.formAddress, geo_lon: data.lon.toString(), geo_lat: data.lat.toString() },
      },
      street,
      streetVisible: street,
      house,
      apartment: data.formAddress.flat,
      index: data.formAddress.postal_code,
      pvzCode: data.code,
    });

    form.validateFields(['city', 'cityVisible', 'street', 'streetVisible', 'house', 'apartment', 'index']);

    setStreetPreValue(cityVisible);
    setHousePreValue(`${cityVisible}, ${street}`);

    setCityIndex(data.formAddress.postal_code);
    onChangeForm();
  };

  const onCalculate = async () => {
    try {
      await form.validateFields([
        'delivery_to_type',
        'delivery_type',
        'country',
        'countryVisible',
        'city',
        'cityVisible',
        'street',
        'streetVisible',
        'house',
      ]);

      const values = form.getFieldsValue();

      const params = renderCalculateCostParams(values, deliveryTypesList, form, 'calculate' as any);

      if (!params) return;
      calculateOrder({
        params,
      }).then(response => {
        if (!response.data.length) {
          showError('Не найдено ни одного предложения по доставке');
          form.setFieldsValue({
            delivery_cost: 0,
            total_cost: 0,
          });
          setDeliveryCostCalculated(false);
        }
        const errors = response.data.flatMap(item => item.error || []);
        if (errors.length) {
          showError(errors.join(', '));
          setDeliveryCostCalculated(false);
        } else if (response.data?.[0]?.priceByCurrency) {
          setDeliveryCostCalculated(true);
          form.setFieldsValue({
            delivery_cost: Number(response.data[0].priceByCurrency) - Number(values.additional_cost || 0),
            total_cost: response.data[0].priceByCurrency,
          });
        }
      });
    } catch (e) {
      showError('Не удалось рассчитать стоимость доставки');
    }
  };

  const onCreateDelivery = async () => {
    try {
      await form.validateFields([
        'delivery_to_type',
        'delivery_type',
        'country',
        'countryVisible',
        'city',
        'cityVisible',
        'street',
        'streetVisible',
        'house',
      ]);

      const values = form.getFieldsValue();

      const params = renderCalculateCostParams(values, deliveryTypesList, form, 'create' as any);
      if (!params) return;
      await form.validateFields();
      calculateOrder({
        params,
      }).then(calculatedDataResponse => {
        if (!calculatedDataResponse.data.length) {
          showError('Не найдено ни одного предложения по доставке');
          form.setFieldsValue({
            delivery_cost: 0,
            total_cost: 0,
          });
        }
        const createParams = renderCreateDeliveryParams(
          values,
          deliveryTypesList,
          form,
          pageMode,
          calculatedDataResponse.data[0],
        );
        if (!createParams) return;
        createOrder({
          params: createParams,
        }).then(response => {
          if (response.data.msg) showError(response.data.msg);
          else {
            if (response.data.dispatchNumber || response.data.number) {
              message.success('Заявка успешно создана');
              window.location.href = '/request-list';
            }
          }
        });
      });
    } catch (error) {
      showError('Не удалось создать заказ');
    }
  };

  useEffect(() => {
    form.setFieldsValue(LocationState);
  }, [LocationState]);

  return (
    <CRContext.Provider
      value={{
        form,
        housePreValue,
        setHousePreValue,
        onChangeForm,
        mapOptions,
        onPlaceMarkClick,
        onCalculate,
        pageMode,
        onCreateDelivery,
        deliveryCostCalculated,
        packageCount,
        setPackageCount,
        streetPreValue,
        setStreetPreValue,
        getPreValue,
        cityIndex,
        setCityIndex,
        checkAddressWithPVS,
        setCheckAddressWithPVS,
      }}
    >
      {children}
    </CRContext.Provider>
  );
};
