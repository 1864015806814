import React, { FC, ReactNode } from 'react';

interface EachProps {
    render: (item: any, index: number) => ReactNode;
    of: any[];
}

export const Each: FC<EachProps> = ({ render, of }) => (
    <>
        {of.map((item, index) => (
            <React.Fragment key={index}>{render(item, index)}</React.Fragment>
        ))}
    </>
);
