import React, {FC, useState} from 'react';
import {Checkbox, Col, Form, Input, Row, Space, Typography} from 'antd';

import {showError} from '@utils/show_error';
import {useForm} from "antd/es/form/Form";
import styles from './Register.module.scss';
import {signUpNewUser, signUpSendSMS} from "@api";
import {phoneMask} from "@typings/masks";
import {MaskedInput} from "antd-mask-input";
import {finishLogin} from "@components/Login/utils";

interface FormValues {
  username: string;
  phone: string;
  code: string;
}

export const Register: FC = () => {
  const [form] = useForm<FormValues>();
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const [registerDisabled, setRegisterDisabled] = useState<boolean>(true);

  const onFinish = (values: FormValues) => {
    signUpNewUser({
      params: {
        UserName: values.phone.replace(/\D/g, ''),
        Password: values.code,
        CompanyCode: values.phone.replace(/\D/g, ''),
        Code: values.code,
      }
    }).then(
      (response) => {
        if (response.data.error) showError(response)
        if (response.data.isActive) {
          finishLogin({
            username: values.phone,
            password: values.code
          });
        }
      }
    )
  };

  const sendSmsCode = () => {
    form.validateFields(['phone']).then(
      () =>
        signUpSendSMS({
          params: {
            UserName: (form.getFieldValue('phone') || '').replace(/\D/g, '')
          },
        }).then(
          () => {
            setCodeSent(true)
          }
        ).catch(
          (error) => {
            if (error?.response?.data && error?.response?.headers?.['content-type'] === "text/plain; charset=utf-8")
              showError(error.response.data)
          }
        )
    );
  }

  const updateBtnDisabled = () => {
    setRegisterDisabled(!form.getFieldValue('phone') || !form.getFieldValue('code') || !codeSent || !form.getFieldValue('agreement'))
  }

  return (
    <Row justify='center' align='middle' style={{minHeight: '100vh'}} className={styles.loginBackground}>
      <Col>
        <Form onFinish={onFinish} form={form} layout={'vertical'} className={styles.registerForm}>
          <Space direction='vertical' size='small'>
            <div className={styles.pageTitle}>
              <div className={styles.title}>Регистрация</div>
              <div className={styles.subtitle}>
                Зарегистрируйтесь, чтобы<br/>зарабатывать на доставке
              </div>
            </div>
            <Form.Item
              label='Логин'
              name='phone'
              rules={[{required: true, message: 'Укажите номер телефона'}]}
            >
              <MaskedInput
                onChange={(e) => form.setFieldValue('phone', e.currentTarget.value)}
                mask={phoneMask}
                placeholder={'+7 (___) ___ - __ - __'}
              />
            </Form.Item>
            <button type={'button'} className={styles.registerBtn} onClick={sendSmsCode}>ПОЛУЧИТЬ ПАРОЛЬ</button>
            <div className={styles.agreementInputContainer}>
              <Form.Item
                valuePropName={'checked'}
                label={<span>Я согласен на обработку<br/>персональных данных, с правилами<br/>пользования и договором оферты.</span>}
                name={'agreement'}
                labelAlign={'right'}
                rules={[{required: true, message: 'Согласитесь с условиями!'}]}
              >
                <Checkbox onChange={updateBtnDisabled}/>
              </Form.Item>
            </div>
            <div className={styles.codeInputContainer}>
              <Form.Item
                label={'Пароль будет сгенерирован автоматически и поступит в SMS'}
                name='code'
                rules={codeSent ? [{required: true, message: 'Введите код из смс!'}] : []}
                // labelCol={{span: 12}}
              >
                <Input placeholder={'Пароль из SMS'} disabled={!codeSent} onChange={updateBtnDisabled}/>
              </Form.Item>
            </div>

            <Space direction='vertical' size='small'>
              <Form.Item>
                <button className={styles.greenBtn} type='submit' disabled={registerDisabled}>
                  РЕГИСТРАЦИЯ
                </button>
              </Form.Item>
              <a href='/login' hidden>Уже есть аккаунт?</a>
            </Space>
          </Space>
        </Form>
      </Col>
    </Row>
  );
};
