import { CSSProperties, FC, useState } from 'react';
import { Checkbox, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface Props {
    isChecked: boolean;
    title: string;
    columnKey: string;
    onChange(title: string, isChecked: boolean): void;
    style?: CSSProperties;
}

export const FilterCheckbox: FC<Props> = ({
    isChecked: isInitialChecked,
    columnKey,
    title,
    onChange: onChangeCb,
    style: _style,
    ...props
}) => {
    const [isChecked, setIsChecked] = useState(isInitialChecked);

    const onChange = ({ target }: CheckboxChangeEvent) => {
        setIsChecked(target.checked);
        onChangeCb(columnKey, target.checked);
    };
    const style = _style
        ? _style
        : {
              marginBottom: '8px',
          };

    return (
        <Row gutter={[0, 16]} {...props}>
            <Checkbox checked={isChecked} onChange={onChange} style={style}>
                {title}
            </Checkbox>
        </Row>
    );
};
