import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type {RootState} from '@store'
import {RenderModal} from "@typings";

// Define a type for the slice state
export interface ModalState {
  isOpen: boolean
  renderModal: RenderModal | null
}

// Define the initial state using that type
const initialState: ModalState = {
  isOpen: false,
  renderModal: null
}

export const modalSlice = createSlice({
  name: 'modal',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<RenderModal>) => {
      state.isOpen = true
      state.renderModal = action.payload
    },
    closeModal: (state) => {
      state.isOpen = false
    },
    closeModalDone: (state) => {
      state.isOpen = false
      state.renderModal = null
    },
  }
})

export const {
  openModal,
  closeModal,
} = modalSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectModalOpen = (state: RootState) => state.modal.isOpen

export default modalSlice.reducer