import {CSSProperties, FC, memo, useEffect, useState} from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
} from 'react-beautiful-dnd';
import {message} from 'antd';
import Modal from 'antd/lib/modal/Modal';

import {DefaultColumn, FilterListColumns, OnColumnViewChange, SetState} from '@typings';

import {FilterCheckbox} from './FilterCheckbox';
import {useAppDispatch} from "@store";
import {closeModal} from "@store/reducers/modal";

interface Props {
  isOpen: boolean;
  filterListColumns: FilterListColumns;
  onColumnViewChange: OnColumnViewChange;
  setColumns: SetState<DefaultColumn[]>;
  dragAll?: boolean;
}

const getItemStyle = (
  draggableStyle: DraggableProvided['draggableProps']['style'],
  isDragging: DraggableStateSnapshot['isDragging']
): CSSProperties => ({
  userSelect: 'none',
  ...draggableStyle,
  display: 'flex',
  alignItems: 'center',
  boxShadow: 'inset 0px 0px 0px 0px rgb(255 255 255), 0px 0px 0px 0px rgb(255 255 255)',
  ...(isDragging
    ? {
      boxShadow:
        'inset 0px 0px 20px 10px rgb(255 255 255), 0px 0px 20px 0px rgb(181 181 181)',
      transition: 'all  0.1s',
    }
    : {}),
});

export const ColumnsViewController: FC<Props> = memo(
  ({isOpen, onColumnViewChange, filterListColumns, setColumns, dragAll}) => {
    const [listColumns, setListColumns] = useState(filterListColumns);
    const dispatch = useAppDispatch();
    const dispatchCloseModal = () => dispatch(closeModal());
    const onModalClose = () => {
      dispatchCloseModal();
    };

    useEffect(() => {
      setListColumns(filterListColumns);
    }, [filterListColumns]);

    function reorder<T>(list: T[], startIndex: number, endIndex: number) {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    }

    const onDragEnd = (result: any) => {
      if (!result.destination) {
        return;
      }

      if ((result.source.index > 1 && result.destination.index > 1) || dragAll) {
        const items = reorder(listColumns, result.source.index, result.destination.index);

        setColumns((prev) => {
          const items = reorder(prev, result.source.index, result.destination.index);
          return items;
        });
        setListColumns(items);
      } else {
        message.error({
          content: 'Номер и индекс являются фиксированными колонками',
        });
      }
    };

    return (
      <Modal
        title='Список колонок'
        open={isOpen}
        onCancel={onModalClose}
        onOk={onModalClose}
        destroyOnClose={true}
        footer={<></>}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable'>
            {(provided) => (
              <div ref={provided.innerRef}>
                {listColumns?.map(({isVisible, title, key}, idx) => (
                  <Draggable key={key} draggableId={key} index={idx}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          provided.draggableProps.style,
                          snapshot.isDragging
                        )}
                      >
                        <FilterCheckbox
                          key={key}
                          isChecked={!!isVisible}
                          title={title}
                          columnKey={key}
                          onChange={onColumnViewChange}
                          style={{
                            padding: '4px',
                          }}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Modal>
    );
  }
);
