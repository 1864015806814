import {FC} from "react";
import {usePackageCheckData} from "@pages/PackageCheckPage/hooks/usePackageCheckData";
import {List, Steps} from "antd";
import styles from '../PackageCheckPage.module.scss'
import {PCListItem} from "@pages/PackageCheckPage/components/PackageList";
import {Exists} from "@utils/Exists";

export const PackageDetail: FC = () => {
  const {
    selectedPackage,
  } = usePackageCheckData();
  return (
    <div className={styles.packageDetail}>
      <div className={styles.packageDetailHeader}>
        <List
          locale={{emptyText: 'Выберите посылку'}}
          size="large"
          className={styles.pcList}
          itemLayout="horizontal"
          dataSource={selectedPackage ? [selectedPackage] : []}
          renderItem={(item) => (
            <Exists visible={!!item}>
              <PCListItem item={item!} isHeader/>
            </Exists>
          )}
        />
      </div>
      <Exists visible={!!selectedPackage}>
        <div className={styles.packageDetailContent}>
          <Steps
            progressDot
            current={-1}
            direction="vertical"
            items={selectedPackage?.steps.map((step, index) => ({
              title: step.title,
              description: step.caption,
            }))}
          />
        </div>
      </Exists>
    </div>
  )
}