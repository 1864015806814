import dayjs from "dayjs";

export const deliveryTypes = {
  'Почта': 'Почта',
  'GTS': 'GTS',
  'СДЕК': 'СДЕК',
  0: 'Все отправления',
}

export interface RequestPageForm {
  delivery_type?: string;
  first_day: string;
  last_day: string;
  full_date: [dayjs.Dayjs, dayjs.Dayjs];
}

export interface RequestDataItem {
  id: number;
  date: string;
  company: string;
  orderNumber: string;
  trackNumber: string;
  recipient: string;
  recipientPhone: string;
  address: string;
  cashOnDelivery: string;
  rate: string;
  status: string;
}

// let columns: ColumnsType<RequestListItem> = [
//     {
//       title: 'Транспортная компания',
//       dataIndex: 'strProvider',
//       key: 'strProvider',
//       sorter: sorterString('strProvider'),
//       ...tableFilter<RequestListItem>('strProvider', rpData),
//     },
//     {
//       title: '№ заказа',
//       dataIndex: 'orderNumber',
//       key: 'orderNumber',
//       sorter: sorterString('orderNumber'),
//       ...tableFilter<RequestListItem>('orderNumber', rpData),
//     },
//     {
//       title: 'Трек номер ТК',
//       dataIndex: 'dispatchNumber',
//       key: 'dispatchNumber',
//       render: (text: string) => <a
//         target={'_blank'}
//         rel={'noreferrer'}
//         href={`/tracking?id=${text}`}
//       >
//         {text}
//       </a>,
//       sorter: sorterNumber('dispatchNumber'),
//       ...tableFilter<RequestListItem>('dispatchNumber', rpData),
//     },
//     {
//       title: 'Получатель',
//       dataIndex: 'fio',
//       key: 'fio',
//       sorter: sorterString('fio'),
//       ...tableFilter<RequestListItem>('fio', rpData),
//     },
//     {
//       title: 'Телефон получателя',
//       dataIndex: 'recipientPhone',
//       key: 'recipientPhone',
//       sorter: sorterString('recipientPhone'),
//       ...tableFilter<RequestListItem>('recipientPhone', rpData),
//     },
//     {
//       title: 'Адрес',
//       dataIndex: 'fullAddress',
//       key: 'fullAddress',
//       sorter: sorterString('fullAddress'),
//       ...tableFilterWithSearch<RequestListItem>('fullAddress', rpData),
//     },
//     {
//       title: 'Налож. платеж',
//       dataIndex: 'cashType',
//       key: 'cashType',
//       sorter: sorterNumber('cashType'),
//       ...tableFilter<RequestListItem>('cashType', rpData),
//     },
//     {
//       title: 'Тариф',
//       dataIndex: 'price',
//       key: 'price',
//       sorter: sorterNumber('price'),
//       ...tableFilter<RequestListItem>('price', rpData),
//     },
//     {
//       title: 'Статус',
//       dataIndex: 'status',
//       key: 'status',
//       sorter: sorterString('status'),
//       ...tableFilter<RequestListItem>('status', rpData),
//     },
//   ];

export const RPColumnsConfig = [
  // {
  //   title: 'Дата',
  //   key: 'date',
  // },
  {
    title: 'Транспортная компания',
    key: 'strProvider',
  },
  {
    title: '№ заказа',
    key: 'orderNumber',
  },
  {
    title: 'Трек номер ТК',
    key: 'dispatchNumber',
  },
  {
    title: 'Получатель',
    key: 'fio',
  },
  {
    title: 'Телефон получателя',
    key: 'recipientPhone',
  },
  {
    title: 'Адрес',
    key: 'fullAddress',
  },
  {
    title: 'Налож. платеж',
    key: 'cashType',
  },
  {
    title: 'Тариф',
    key: 'price',
  },
  {
    title: 'Статус',
    key: 'status',
  },
]

export interface RequestListItem {
  storePlaceName: string;
  refundRequestDescr: string;
  cashType: string;
  id: number;
  userID: string;
  userCreator: string;
  serviceType: null | string;
  strProvider: null | string;
  description: null | string;
  number: string;
  dispatchNumber: string;
  status: string;
  fio: string;
  recipientPhone: string;
  senderPhone: null | string;
  fullAddress: string;
  price: number;
  paymentType: number;
  createDate: string;
  priceType: number;
  parcelCancel: number;
  isPay: null | boolean;
  discount: null | number;
  discountID: null | number;
  marketPvzID: number;
  existRefundRequest: number;
  isExternal: boolean;
  companyCode: null | string;
  providerID: number;
  prevNumber: null | string;
  prevDispatchNumber: null | string;
  orderNumber: string;
}
