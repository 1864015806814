import {FC, useEffect, useMemo, useRef, useState} from 'react';
import * as React from 'react';
import {DatePicker, Form, FormInstance} from 'antd';

import {DATE_FORMAT} from '@constants';

import styles from './styles.module.scss';
import {RangeValue} from "@typings/date";
import dayjs, {Dayjs} from "dayjs";

interface Props {
  first_day: string | undefined;
  last_day: string | undefined;
  dateFormat?: string;
  maxDays?: number;
  onChange?: (value: RangeValue<Dayjs>) => void;
  name: string;
  form: FormInstance;
  placeholder?: [string, string];
  requireBothDates?: boolean;
  allowEmpty?: boolean;
}

interface FormValues {
  [name: string]: [dayjs.Dayjs, dayjs.Dayjs];
}

export const DateRangePicker: FC<Props> = ({
                                             first_day,
                                             last_day,
                                             maxDays,
                                             dateFormat = DATE_FORMAT,
                                             onChange,
                                             name,
                                             form,
                                             placeholder,
                                             requireBothDates,
                                             allowEmpty = true,
                                           }) => {
  const initialState = {firstDay: first_day, lastDay: last_day};
  const initialValue: Partial<FormValues> = useMemo(() => {
    return {
      [name]: [
        initialState.firstDay ? dayjs(initialState.firstDay, dateFormat) : undefined,
        initialState.firstDay ? dayjs(initialState.lastDay, dateFormat) : undefined,
      ] as FormValues['name'],
    };
  }, [initialState]);
  const [value, setValue] = useState(initialValue);
  const [isOpen, setIsOpen] = useState(false);
  const itemRef = useRef(null);

  const updateForm = (newValue: Partial<FormValues>) => {
    setValue((value) => ({...value, ...newValue}));
  };
  const onChangeDate = (newValue: RangeValue<Dayjs>) => {
    updateForm({
      [name]: (newValue?.[0] ? newValue : newValue?.reverse()) as [
        Dayjs,
        Dayjs
      ],
    });
  };

  useEffect(() => {
    if (JSON.stringify(value[name]) !== JSON.stringify(initialValue[name])) {
      onChange?.(value[name] || [null, null]);
      if (value[name]?.every((elem) => !elem) && allowEmpty) setIsOpen(false);
      else setIsOpen(value[name] !== null ? !value[name]?.every(Boolean) : true);
    }
  }, [value]);

  useEffect(() => {
    if (!requireBothDates || value[name]?.every(Boolean)) form.setFieldsValue(value);
  }, [form, value]);

  return (
    <div ref={itemRef}>
      <Form.Item name={name}>
        <DatePicker.RangePicker
          placeholder={placeholder}
          onBlur={(event) => {
            if (
              itemRef.current &&
              !(itemRef.current as unknown as Node).contains(event.relatedTarget) &&
              !document.querySelector('.ant-picker-panels')?.contains(event.relatedTarget as Node) &&
              isOpen
            ) {
              setIsOpen(false);
              // updateForm(initialValue);
            }
          }}
          onClick={() => {
            updateForm({
              [name]: null as unknown as [Dayjs, Dayjs],
            })
          }
          }
          allowEmpty={!allowEmpty ? [false, false] : undefined}
          open={isOpen}
          format={dateFormat}
          onCalendarChange={onChangeDate}
          className={styles.dateRangeInput}
          disabledDate={(current) => {
            if (!maxDays) return false;
            const dates = value[name];
            if (!dates) {
              return false;
            }
            const tooLate = dates[0] && current.diff(dates[0], 'days') >= maxDays;
            const tooEarly = dates[1] && dates[1].diff(current, 'days') >= maxDays;
            return tooEarly || tooLate;
          }}
        />
      </Form.Item>
    </div>
  );
};
