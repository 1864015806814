import React, {FC} from 'react';
import {Content} from 'antd/lib/layout/layout';
import {Breadcrumb, Button, Dropdown, Layout, Space} from 'antd';
import styles from './TemplatePage.module.scss';
import {
  CalculatorOutlined,
  CompassOutlined,
  FileAddOutlined,
  PrinterOutlined,
  SettingFilled,
  UserOutlined,
} from '@ant-design/icons';
import {Footer} from 'antd/es/layout/layout';
import clsx from 'clsx';
import {SetTitleProvider} from '@providers/SetTitleProvider';
import {useLocation} from 'react-router-dom';
import {getNestedPagePath} from '../../constants/pages';
import {BtnHint} from '@components/BtnHint';
import {useAppDispatch, useAppSelector} from '@store';
import {cancelOrder, getOrderLabel} from '@api/services/orders';
import {showError} from '@utils/show_error';
import {logout} from '@utils/auth/auth';
import {refreshDataTrigger, toggleShowHintsForOrders} from '@store/reducers/selectedOrders';
import {RequestListItem} from '@typings/pages/requestPageTypes';
import {RenderModal} from "@typings";
import {closeModal, openModal} from "@store/reducers/modal";
import {DeleteConfirmationModal} from "./components/DeleteConfirmationModal";
import {callCourierMenu, settingsDropDownMenu} from "@typings/pages/TemplatePage";

interface Props {
  children: React.ReactNode;
}

const UserInfoButton: FC = () => {
  return (
    <Space direction='horizontal'>
      <UserOutlined className={styles.userIcon}/>
      <div className={styles.userInfoText}>
        <span>Иванов</span>
        <span>Иван Иванович</span>
      </div>
    </Space>
  );
};

const BalanceLabel: FC = () => {
  return (
    <div className={styles.balanceLabel}>
      <span>Баланс</span>
      <span className={clsx(styles.colored, styles.balance)}>0 ₽</span>
    </div>
  );
};

const MenuHeader: FC = () => {
  const dispatch = useAppDispatch();
  const {pathname} = useLocation();
  const dispatchShowModal = (renderModal: RenderModal) => dispatch(openModal(renderModal));
  const dispatchCloseModal = () => dispatch(closeModal());
  const selectedOrders = useAppSelector(state => state.selectedOrders.data);

  const onCancelDelivery = async (selectedOrders: RequestListItem[]) => {
    try {
      await Promise.all(
        selectedOrders.map(async order => {
          const response = await cancelOrder({
            params: {
              userID: order.userID,
              number: order.number,
              dispatchNumber: order.dispatchNumber,
              serviceType: order.serviceType,
              type: order.status,
            },
          });
          if (response.data.errorMsg) showError(response.data.errorMsg);
        }),
      ).finally(() => dispatch(refreshDataTrigger()));
    } catch (error) {
      console.error('onCancelDelivery error', error);
      showError('При отмене заказа произошла ошибка')
    }
  };

  const onPrintLabelClick = async (type: 'label' | 'invoice') => {
    if (!selectedOrders?.length) return;
    for (const order of selectedOrders) {
      const response = await getOrderLabel({
        params: {
          number: order.number,
          type: type === 'invoice' ? 'INVOICE' : 'BARCODE',
        },
      });
      if (response.data.errorMsg) showError(response.data.errorMsg);
      if (response.data.urlLabels?.length) {
        response.data.urlLabels.forEach(url => {
          window.open(url, '_blank');
        });
      }
    }
  };

  const showDeleteConfirmationModal = () => {
    dispatchShowModal((isOpen) => (
      <DeleteConfirmationModal open={isOpen} onOk={() => {
        dispatchCloseModal();
        onCancelDelivery(selectedOrders!);
      }} onCancel={() => {
        dispatchCloseModal();
      }}/>
    ))
  }

  const onCancelOrderClick = () => {
    if (!selectedOrders?.length) {
      dispatch(toggleShowHintsForOrders(true));
    } else {
      showDeleteConfirmationModal();
    }
  };

  return (
    <div>
      <div className={styles.menuContainer}>
        <div className={styles.menu}>
          <Space direction='horizontal' className={styles.bntSection}>
            <Space direction='horizontal' className={styles.btnGroup}>
              <BtnHint k={'request.list'}>
                <Button href={'/request-list'}>Список отправлений</Button>
              </BtnHint>
              <BtnHint k={'request.create'}>
                <Button href={'/create-request'}>
                  <FileAddOutlined/>
                  Создать заказ
                </Button>
              </BtnHint>
            </Space>
            <Space direction='horizontal' className={styles.btnGroup}>
              <BtnHint k={'request.calculator'}>
                <Button href={'/calculate'}>
                  <CalculatorOutlined/>
                </Button>
              </BtnHint>
              <BtnHint k={'request.map'}>
                <Button href={'/map'}>
                  <CompassOutlined/>
                </Button>
              </BtnHint>
            </Space>
            <Space direction='horizontal' className={styles.btnGroup}>
              <BtnHint k={'request.printLabels'}>
                <Button
                  className={styles.getLabelBtn}
                  disabled={(selectedOrders?.length || 0) === 0}
                  onClick={() => onPrintLabelClick('label')}
                >
                  <PrinterOutlined/>
                  Печать этикеток
                </Button>
              </BtnHint>
              <BtnHint k={'request.printInvoices'}>
                <Button
                  className={styles.getLabelBtn}
                  disabled={(selectedOrders?.length || 0) === 0}
                  onClick={() => onPrintLabelClick('invoice')}
                >
                  <PrinterOutlined/>
                  Печать накладных
                </Button>
              </BtnHint>
              <BtnHint k='request.tracking'>
                <Button href={'/tracking'}>Отследить посылку</Button>
              </BtnHint>
              {pathname === '/request-list' && (
                <BtnHint k='request.cancelOrder'>
                  <Button type='primary' danger={(selectedOrders?.length || 0) > 0} onClick={onCancelOrderClick}>
                    Отменить заказ
                  </Button>
                </BtnHint>
              )}
            </Space>
          </Space>
          <Space direction='horizontal' className={styles.userInfoSection}>
            <BtnHint k='request.callCourier'>
              <Dropdown trigger={['click']} menu={callCourierMenu}>
                <Button type='primary'>
                  Вызвать курьера
                </Button>
              </Dropdown>
            </BtnHint>
            {/*<UserInfoButton/>*/}
            <Button type={'text'} className={styles.settingBtn}>
              <BtnHint k={'request.settings'}>
                <Dropdown trigger={['click']} menu={settingsDropDownMenu}>
                  <SettingFilled/>
                </Dropdown>
              </BtnHint>
            </Button>
          </Space>
        </div>
      </div>
      <div className={styles.pageAndUserInfoRow}>
        <Breadcrumb separator='>' items={getNestedPagePath(pathname)}/>
        <BalanceLabel/>
      </div>
    </div>
  );
};

export const TemplatePage: FC<Props> = ({children}) => {
  return (
    <SetTitleProvider>
      <Layout className={styles.pageLayout}>
        <MenuHeader/>
        <Content>{children}</Content>
        <Footer className={styles.footer}>
          <div className={styles.footerContentContainer}>
            <div className={styles.footerItem}>
              <a href={'/request-list'}>Частным лицам</a>
              <a href={'/create-request'}>Отправить или получить</a>
              <a target={'_blank'} href={'/tracking'}>
                Отследить посылку
              </a>
            </div>
            <div className={styles.footerItem}>
              <a href={'/text'}>Сервисы и продукты</a>
              <a href={'/text'}>Правила приёма и доставки</a>
            </div>
            <div className={styles.footerItem}>
              <a href={'/text'}>Правила приёма и доставки (оферта)</a>
              <a href={'/text'}>контакты</a>
            </div>
            <div className={styles.footerItem}>
              <a href={'/text'}>Поддержка</a>
              <a href={'/text'}>Чат в мессенджерах</a>
              <span>+7 (999)-009-00-00</span>
            </div>
          </div>
        </Footer>
      </Layout>
    </SetTitleProvider>
  );
};
