export const formatToMoney = (value: number) => {
    const money = new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(
        value
    );

    if (money === 'не число ₽') return '';

    return money
        .replace(/,(\d\d).₽/, (_, firstGroup) => {
            // const defaultValue = '00';

            // if (firstGroup === defaultValue) {
            //     return '';
            // }

            return `.${firstGroup}`;
        })
        .replace(/\s/g, '\u202F');
};

export const amountToMoney = (value: string) => {
    const money = new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(
        parseFloat(value)
    );

    if (money === 'не число ₽') return '';

    return money
        .replace(/,(\d\d).₽/, (_, firstGroup) => {
            const defaultValue = '00';

            if (firstGroup === defaultValue) {
                return '';
            }

            return `.${firstGroup}`;
        })
        .replace(/\s/g, '\u202F');
};
