import {FC, useEffect, useState} from "react";
import {RequestListItem} from "@typings/pages/requestPageTypes";
import {fetchRequestDetail} from "@api/services/orders";
import {fetchRequestDetailResponse} from "@typings/models/orders";
import {showError} from "@utils/show_error";
import {Spin} from "antd";
import styles from "../RequestsPage.module.scss";
import {Each} from "@utils/Each";
import {deliveryToTypes} from "@typings/pages/createRequestTypes";

interface RequestDetailProps {
  request: RequestListItem;
}

export const RequestDetail: FC<RequestDetailProps> = ({request}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [requestData, setRequestData] = useState<fetchRequestDetailResponse>();

  useEffect(() => {
    if (request?.number) {
      setLoading(true);
      fetchRequestDetail({params: {number: request.number}}).then((res) => {
        setRequestData(res.data);
      }).catch(showError).finally(() => {
        setLoading(false);
      });
    }
  }, [request]);

  return (
    <Spin spinning={loading}>
      <div className={styles.requestDetail}>
        <Each render={(packageItem: fetchRequestDetailResponse['packages'][0]) => (
          <div className={styles.detailRow}>
            <div className={styles.detailItem}>
              <div className={styles.detailItemLabel}>Тип вложения</div>
              <div className={styles.detailItemValue}>{packageItem.items[0].wareKey}</div>
            </div>
            <div className={styles.detailItem}>
              <div className={styles.detailItemLabel}>Вес (гр)</div>
              <div className={styles.detailItemValue}>{packageItem.items[0].weight}</div>
            </div>
            <div className={styles.detailItem}>
              <div className={styles.detailItemLabel}>Длина (см)</div>
              <div className={styles.detailItemValue}>{packageItem.size.length}</div>
            </div>
            <div className={styles.detailItem}>
              <div className={styles.detailItemLabel}>Ширина (см)</div>
              <div className={styles.detailItemValue}>{packageItem.size.width}</div>
            </div>
            <div className={styles.detailItem}>
              <div className={styles.detailItemLabel}>Высота (см)</div>
              <div className={styles.detailItemValue}>{packageItem.size.height}</div>
            </div>
            <div className={styles.detailItem}>
              <div className={styles.detailItemLabel}>Вид доставки</div>
              <div
                className={styles.detailItemValue}>{requestData?.recipient?.address.pvzCode ? deliveryToTypes.pvz : deliveryToTypes.door}</div>
            </div>
            <div className={styles.detailItem}>
              <div className={styles.detailItemLabel}>Отправитель</div>
              <div className={styles.detailItemValue}>{requestData?.realSeller?.contactName}</div>
            </div>
            <div className={styles.detailItem}>
              <div className={styles.detailItemLabel}>Тел. Отправителя</div>
              <div className={styles.detailItemValue}>{requestData?.realSeller?.phone}</div>
            </div>
            <div className={styles.detailItem}>
              <div className={styles.detailItemLabel}>Плательщик</div>
              <div
                className={styles.detailItemValue}>{requestData?.paymenttype === 2 ? 'Получатель' : 'Отправитель'}</div>
            </div>
            <div className={styles.detailItem}>
              <div className={styles.detailItemLabel}>Индекс</div>
              <div
                className={styles.detailItemValue}>{requestData?.recipient.address.postCode}</div>
            </div>
            <div className={styles.detailItem}>
              <div className={styles.detailItemLabel}>Оценочная стоимость</div>
              <div
                className={styles.detailItemValue}>{requestData?.estimatedCost || 0}</div>
            </div>
            <div className={styles.detailItem}>
              <div className={styles.detailItemLabel}>Наценка</div>
              <div
                className={styles.detailItemValue}>{requestData?.addDeliveryPrice || 0}</div>
            </div>
          </div>
        )} of={requestData?.packages || []}/>
      </div>
    </Spin>
  )
}