import {message} from 'antd';
import {AxiosResponse} from "axios";

export const showError = (response: AxiosResponse | string): void => {
  if (typeof response === 'string') {
    message.error({content: response});
    return;
  }
  if (response.data.error)
    message.error({
      content: response.data.error,
    });
};