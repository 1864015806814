import {FormInstance} from 'antd';
import {Rule} from 'antd/lib/form';
import dayjs from 'dayjs';

import {DATE_FORMAT} from '@constants';

export const getNumberFormat = (initValue: string) => {
  if (!initValue?.trim()) return initValue;

  return initValue.trim().match(/[0-9]/g)?.join('') || '';
};

export const textRulesRequired: Rule[] = [
  {
    message: 'Поле должно содержать только буквы',
    pattern: /^[А-яЁё A-z]+$/i,
    transform: (text) => {
      if (!text) return undefined;
      return text;
    },
  },
  {
    required: true,
    message: '',
  },
];

export const dateRules: Rule[] = [
  {
    message: 'Не валидная дата',
    transform: (date) => {
      const dayjsDate = dayjs(date, 'DDMMYYYY').format('DD.MM.YYYY');

      if (!date) return undefined;

      return dayjsDate;
    },
    pattern: /^\d{2}[./-]\d{2}[./-]\d{4}$/,
  },
];

export const dateRulesRequired: Rule[] = [
  {
    message: 'Не валидная дата',
    transform: (date) => {
      const dayjsDate = dayjs(date, 'DDMMYYYY').format('DD.MM.YYYY');
      if (!date) return undefined;
      return dayjsDate;
    },
    pattern: /^\d{2}[./-]\d{2}[./-]\d{4}$/,
  },
  {
    required: true,
    message: 'Это поле необходимо!',
  },
];

export const hoursRules: Rule[] = [
  {
    message: 'Максимальная длительность смены - 24 часа',
    pattern: /^[0-9]{1,2}$/i,
    transform: (hours) => {
      const dayjsHours = dayjs(hours, 'HH').format('HH');
      if (!hours) return undefined;
      return dayjsHours;
    },
  },
];

export const textRules: Rule[] = [
  {
    message: 'Поле должно содержать только буквы',
    pattern: /^[А-яЁё A-z]+$/i,
  },
];

export const phoneRulesRequired: Rule[] = [
  {
    message: 'Поле должно содержать только цифры',
    pattern: /^[+0-9() ]+$/i,
    transform: (numbers) => {
      if (!numbers) return undefined;

      return getNumberFormat(numbers);
    },
  },

  {
    transform: (numbers: string) => {
      if (!getNumberFormat(numbers) || getNumberFormat(numbers).length < 11) return undefined;

      return getNumberFormat(numbers);
    },
    required: true,
    message: 'Это поле необходимо!',
  },
];

export const numberRulesRequired: Rule[] = [
  {
    message: 'Поле должно содержать только цифры',
    pattern: /^[+0-9() ]+$/i,
    transform: (numbers) => {
      if (!numbers) return undefined;
      return numbers;
    },
  },
  {
    required: true,
    message: '',
  },
];

export const positiveNumberRules: Rule[] = [
  {
    message: 'Поле должно содержать только цифры',
    pattern: /^[+\-0-9() ]+$/i,
    transform: (numbers) => {
      if (!numbers) return undefined;
      return numbers;
    },
  },
  {
    validator: (_, value) => {
      if (value && value <= 0) {
        return Promise.reject(new Error('Число должно быть больше нуля'));
      }
      return Promise.resolve();
    },
  },
];

export const numberRules: Rule[] = [
  {
    message: 'Поле должно содержать только цифры',
    pattern: /^[+0-9() ]+$/i,
  },
];

export const requiredRules: Rule[] = [
  {
    required: true,
    message: '',
    transform: (text) => {
      if (!text) return undefined;
      return text;
    },
  },
];

export const dateRequiredRules: Rule[] = [
  {
    required: true,
    message: 'Это поле необходимо!',
    type: 'object',
  },
];

export const dadataInputRequiredRules: Rule[] = [
  {
    required: true,
    message: 'Это поле необходимо!',
    transform: (inputValue) => {
      if (!inputValue?.value) return undefined;
      return inputValue.value;
    },
  },
];

export const contractDateRules: Rule[] = [
  {
    message: 'Нельзя проставить дату позже текущей',
    transform: (date: dayjs.Dayjs) => {
      if (!date || !date.isValid()) return undefined;
      if (date.isAfter(dayjs())) return false;
      return date.format(DATE_FORMAT);
    },
    pattern: /^\d{2}[./-]\d{2}[./-]\d{4}$/,
  },
  {
    required: true,
    message: 'Это поле необходимо!',
  },
];

// Дата окончания паспорта не должна быть раньше сегодня
// Миграционная карта: дата выдачи не раньше даты выдачи паспорта, дата окончания не позже даты окончания паспорта
// Регистрация: (если МК есть) дата выдачи не раньше даты выдачи миграционной карты, дата окончания не позже даты окончания миграционной карты, если нет МК, то дата выдачи не раньше даты выдачи паспорта, дата окончания не позже даты окончания паспорта
// Патент: дата выдачи не раньше даты выдачи миграционной карты, дата окончания не позже даты окончания паспорта

export const dateOfExpRules: Rule[] = [
  {
    transform: (date) => {
      const dayjsDate = dayjs(date, 'DDMMYYYY').format('DD.MM.YYYY');
      if (!date) return undefined;
      return dayjsDate;
    },
    pattern: /^\d{2}[./-]\d{2}[./-]\d{4}$/,
    message: 'Не валидная дата',
  },
  {
    validator: (rule, value, callback) => {
      if (value && dayjs(value, 'DDMMYYYY').isBefore(dayjs(), 'day')) {
        callback('Дата не может быть раньше сегодняшней');
      }
      callback();
    },
  },
];

export const dateOfIssueRules: Rule[] = [
  {
    transform: (date) => {
      const dayjsDate = dayjs(date, 'DDMMYYYY').format('DD.MM.YYYY');
      if (!date) return undefined;
      return dayjsDate;
    },
    pattern: /^\d{2}[./-]\d{2}[./-]\d{4}$/,
    message: 'Не валидная дата',
  },
  {
    validator: (rule, value, callback) => {
      if (value && dayjs(value, 'DDMMYYYY').isAfter(dayjs(), 'day')) {
        callback('Дата не может быть позже сегодняшней');
      }
      callback();
    },
  },
];

export const dateOfIssueRulesRequired: Rule[] = [...requiredRules, ...dateOfIssueRules];

export const dateOfExpRulesRequired: Rule[] = [...requiredRules, ...dateOfExpRules];

export const dateIsBeforeRule = (
  form: FormInstance,
  key: string,
  errorText: string,
  format = 'DDMMYYYY'
): Rule => ({
  validator: (rule, value, callback) => {
    const formKey = key.includes('.') ? key.split('.')[0] : key;
    const dateOfIssue = !key.includes('.')
      ? form.getFieldValue(key)
      : form.getFieldValue(formKey)?.[key.split('.')[1]];
    if (
      dateOfIssue &&
      value &&
      dayjs(value, format).isBefore(dayjs(dateOfIssue, format), 'day')
    ) {
      callback(errorText);
    }
    callback();
  },
});

export const dateIsAfterRule = (
  form: FormInstance,
  key: string,
  errorText: string,
  format = 'DDMMYYYY'
): Rule => ({
  validator: (rule, value, callback) => {
    const dateOfExp = form.getFieldValue(key);
    if (dateOfExp && value && dayjs(value, format).isAfter(dayjs(dateOfExp, format), 'day')) {
      callback(errorText);
    }
    callback();
  },
});

export const migCardDateOfIssueRules = (form: FormInstance): Rule[] => [
  dateIsBeforeRule(
    form,
    'passport_date_of_issue',
    'Дата выдачи не может быть раньше даты выдачи паспорта'
  ),
  ...dateOfIssueRules,
];

export const migCardDateOfIssueRulesRequired = (form: FormInstance): Rule[] => [
  dateIsBeforeRule(
    form,
    'passport_date_of_issue',
    'Дата выдачи не может быть раньше даты выдачи паспорта'
  ),
  ...dateOfIssueRules,
  ...requiredRules,
];

export const migCardDateOfExpRules = (form: FormInstance): Rule[] => [
  dateIsAfterRule(
    form,
    'passport_date_of_exp',
    'Дата окончания не может быть позже даты окончания паспорта'
  ),
  ...dateOfExpRules,
];

export const migCardDateOfExpRulesRequired = (form: FormInstance): Rule[] => [
  dateIsAfterRule(
    form,
    'passport_date_of_exp',
    'Дата окончания не может быть позже даты окончания паспорта'
  ),
  ...dateOfExpRules,
  ...requiredRules,
];

export const regDateOfIssueRules = (form: FormInstance): Rule[] => [
  dateIsBeforeRule(
    form,
    'm_date_of_issue',
    'Дата выдачи не может быть раньше даты выдачи миграционной карты'
  ),
  dateIsBeforeRule(
    form,
    'passport_date_of_issue',
    'Дата выдачи не может быть раньше даты выдачи паспорта'
  ),
  ...dateOfIssueRules,
];

export const regDateOfIssueRulesRequired = (form: FormInstance): Rule[] => [
  dateIsBeforeRule(
    form,
    'm_date_of_issue',
    'Дата выдачи не может быть раньше даты выдачи миграционной карты'
  ),
  dateIsBeforeRule(
    form,
    'passport_date_of_issue',
    'Дата выдачи не может быть раньше даты выдачи паспорта'
  ),
  ...dateOfIssueRules,
  ...requiredRules,
];

export const workerRegDateOfIssueRules = (form: FormInstance): Rule[] => {
  if (form.getFieldValue('citizenship')?.name === 'РФ')
    return [...dateOfIssueRules, ...dateRequiredRules];
  else
    return [
      dateIsBeforeRule(
        form,
        'm_date_of_issue',
        'Дата выдачи не может быть раньше даты выдачи миграционной карты',
        'YYYY-MM-DD'
      ),
      dateIsBeforeRule(
        form,
        'passport.date_of_issue',
        'Дата выдачи не может быть раньше даты выдачи паспорта',
        'YYYY-MM-DD'
      ),
      ...dateOfIssueRules,
      ...dateRequiredRules,
    ];
};

export const regRussianDateOfIssueRulesRequired: Rule[] = [...dateOfIssueRules, ...requiredRules];

export const patentDateOfIssueRules = (form: FormInstance): Rule[] => [
  dateIsBeforeRule(
    form,
    'm_date_of_issue',
    'Дата выдачи не может быть раньше даты выдачи миграционной карты'
  ),
  ...dateOfIssueRules,
];

export const patentDateOfIssueRulesRequired = (form: FormInstance): Rule[] => [
  dateIsBeforeRule(
    form,
    'm_date_of_issue',
    'Дата выдачи не может быть раньше даты выдачи миграционной карты'
  ),
  ...dateOfIssueRules,
  ...requiredRules,
];
