import { DAValue, DAVOptionType, DCAValue } from '@components/DaDataCountryAutoComplete';

export enum deliveryToTypes {
  door = 'До двери',
  pvz = 'До ПВЗ',
}

export enum packageTypes {
  'Одежда' = 'Одежда',
  'Цветы' = 'Цветы',
  'Духи' = 'Духи',
  'Продукты' = 'Продукты',
  'Обувь' = 'Обувь',
  // 'Другое' = 'Другое',
  'ТНП' = 'ТНП',
}

interface SizeParams {
  width: number;
  height: number;
  length: number;
  weight: number;
}

export interface CreateRequestFormData {
  index: string;
  country: DCAValue;
  countryVisible: string;
  order_number: string;
  delivery_type: string;
  city: DAValue;
  cityVisible: string;
  visible_full_address: string;
  streetVisible: string;
  delivery_to_type: keyof typeof deliveryToTypes;
  street: string;
  house: string;
  apartment: string;
  pvzCode: string;

  recipient_name: string;
  recipient_phone: string;
  recipient_pays: boolean;
  sender_name: string;
  sender_phone: string;
  sender_pays: boolean;

  package_type: keyof typeof packageTypes | string;
  packages_size: SizeParams[];
  estimated_cost: number;
  delivery_cost: number;
  total_cost: number;
  additional_cost: number;
}

export enum getPVZProvId {
  empty = 0
}
