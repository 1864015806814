import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { PackageItem } from '@typings/pages/packageCheckTypes';
import { RequestListItem } from '@typings/pages/requestPageTypes';
import { getOrdersList } from '@api/services/orders';
import { CD_DATE_FORMAT } from '@constants';
import dayjs from 'dayjs';
import { getTrackingInfo, getTrackingSimple } from '@api/services/tracking';
import { Provider } from 'react-redux';

interface PCContextType {
  page: number;
  pageSize: number;
  onChangePagination: (page: number, pageSize: number) => void;
  PCdata: PackageItem[];
  setPCData: React.Dispatch<React.SetStateAction<PackageItem[]>>;
  selectedPackage: PackageItem | null;
  setSelectedPackage: React.Dispatch<React.SetStateAction<PackageItem | null>>;
  loading: boolean;
}

const PCContext = createContext<PCContextType | undefined>(undefined);

export const usePackageCheckData = () => {
  const context = useContext(PCContext);
  if (!context) {
    throw new Error('usePackageCheckData must be used within a PackageCheckDataProvider');
  }
  return context;
};

interface PCDataProviderProps {
  children: ReactNode;
  defaultSelected?: number | string | null;
}

const PAGE_SIZE_DEFAULT = 50;

export const PackageCheckDataProvider: FC<PCDataProviderProps> = ({ children, defaultSelected }) => {
  const [{ page, pageSize }, setPagination] = useState<{
    page: number;
    pageSize: number;
  }>({ page: 1, pageSize: PAGE_SIZE_DEFAULT });

  const [PCdata, setPCData] = useState<PackageItem[]>([]);
  const [selectedPackage, setSelectedPackage] = useState<PackageItem | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [rpData, setRPData] = useState<RequestListItem[]>([]);
  const onChangePagination = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  useEffect(() => {
    if (defaultSelected && PCdata) {
      setSelectedPackage(PCdata.find(item => Number(item.id) === Number(defaultSelected)) || null);
    }
  }, [defaultSelected, PCdata]);

  const fetchRPData = () => {
    setLoading(true);
    getOrdersList({
      params: {
        deliveryDate: dayjs().subtract(30, 'days').format(CD_DATE_FORMAT),
        deliveryToDate: dayjs().format(CD_DATE_FORMAT),
      },
    }).then(response => {
      setRPData(response.data.deliveryList);
      setLoading(false);
    });
  };

  const fetchTrackDataForPackage = useCallback(
    (number: number): Promise<PackageItem | undefined> => {
      return new Promise(resolve => {
        try {
          getTrackingSimple({
            params: {
              number: number.toString(),
            },
          })
            .then(responseSimple => {
              if (responseSimple.data && responseSimple.data.length) {
                const simpleData = responseSimple.data[responseSimple.data.length - 1];
                getTrackingInfo({
                  params: {
                    number: number.toString(),
                  },
                }).then(responseFull => {
                  if (responseFull.data && responseFull.data.length) {
                    resolve({
                      title: `Посылка ${simpleData.dispatchNumber.toString()}`,
                      status: simpleData.statusName,
                      id: Number(simpleData.number),
                      steps: responseFull.data.map(step => ({
                        title: step.statusName,
                        caption: `${dayjs(step.statusDate).format('DD MMMM YYYY, HH:mm')} ${step.cityName}`,
                      })),
                    });
                  } else
                    resolve({
                      title: `Посылка ${simpleData.dispatchNumber.toString()}`,
                      status: simpleData.statusName,
                      id: Number(simpleData.number),
                      steps: [],
                    });
                });
              } else resolve(undefined);
            })
            .catch(() => {
              resolve(undefined);
            });
        } catch (e) {
          resolve(undefined);
        }
      });
    },
    [rpData],
  );

  const processRPData = useCallback(async () => {
    const newPCData: PackageItem[] = [];
    for (const order of rpData) {
      if (order) {
        const trackData = await fetchTrackDataForPackage(Number(order.number));
        if (trackData) {
          newPCData.push(trackData);
        }
      }
    }
    setPCData(newPCData);
  }, [rpData]);

  useEffect(() => {
    setLoading(true);
    if (!defaultSelected) {
      // fetchRPData()
      //   wait
    } else {
      fetchTrackDataForPackage(Number(defaultSelected)).then(data => {
        if (data) {
          setPCData(prev => [data, ...prev].filter((item, index, list) => list.indexOf(item) === index));
        }
        setLoading(false);
      });
    }
  }, [defaultSelected]);

  useEffect(() => {
    setLoading(true);
    if (rpData.length) processRPData().then(() => setLoading(false));
  }, [rpData]);

  return (
    <PCContext.Provider
      value={{
        page,
        pageSize,
        onChangePagination,
        PCdata,
        setPCData,
        selectedPackage,
        setSelectedPackage,
        loading,
      }}
    >
      {children}
    </PCContext.Provider>
  );
};
