// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeleteConfirmationModal_DeleteConfirmationModal__Ap1Xc .DeleteConfirmationModal_cancelBtn__L89iV {
  background: rgb(247, 205, 69) !important;
  color: white !important;
  border: none !important;
}
.DeleteConfirmationModal_DeleteConfirmationModal__Ap1Xc .DeleteConfirmationModal_okBtn__0BnYM {
  background: rgb(74, 163, 84) !important;
  color: white !important;
  border: none !important;
}
.DeleteConfirmationModal_DeleteConfirmationModal__Ap1Xc .ant-modal-close {
  display: none;
}
.DeleteConfirmationModal_DeleteConfirmationModal__Ap1Xc .ant-modal-title {
  text-transform: uppercase;
}
.DeleteConfirmationModal_DeleteConfirmationModal__Ap1Xc .ant-modal-footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 20px;
}
.DeleteConfirmationModal_DeleteConfirmationModal__Ap1Xc .ant-modal-footer .ant-btn {
  padding: 4px 70px;
  text-transform: uppercase;
  border-radius: 15px;
  margin: 0 !important;
}
.DeleteConfirmationModal_DeleteConfirmationModal__Ap1Xc .ant-modal-content {
  border-radius: 20px;
  padding-inline: 30px;
}`, "",{"version":3,"sources":["webpack://./src/templates/TemplatePage/components/DeleteConfirmationModal.module.scss"],"names":[],"mappings":"AAEI;EACI,wCAAA;EACA,uBAAA;EACA,uBAAA;AADR;AAII;EACI,uCAAA;EACA,uBAAA;EACA,uBAAA;AAFR;AAOQ;EACI,aAAA;AALZ;AAQQ;EACI,yBAAA;AANZ;AASQ;EACI,aAAA;EACA,2BAAA;EACA,8BAAA;EACA,gBAAA;AAPZ;AAUQ;EACI,iBAAA;EACA,yBAAA;EACA,mBAAA;EACA,oBAAA;AARZ;AAWQ;EACI,mBAAA;EACA,oBAAA;AATZ","sourcesContent":[".DeleteConfirmationModal {\n\n    .cancelBtn {\n        background: rgb(247, 205, 69) !important;\n        color: white !important;\n        border: none !important;\n    }\n\n    .okBtn {\n        background: rgb(74, 163, 84) !important;\n        color: white !important;\n        border: none !important;\n    }\n\n    :global {\n\n        .ant-modal-close {\n            display: none;\n        }\n\n        .ant-modal-title {\n            text-transform: uppercase;\n        }\n\n        .ant-modal-footer {\n            display: flex;\n            flex-direction: row-reverse;\n            justify-content: space-between;\n            margin-top: 20px;\n        }\n\n        .ant-modal-footer .ant-btn {\n            padding: 4px 70px;\n            text-transform: uppercase;\n            border-radius: 15px;\n            margin: 0 !important;\n        }\n\n        .ant-modal-content {\n            border-radius: 20px;\n            padding-inline: 30px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DeleteConfirmationModal": `DeleteConfirmationModal_DeleteConfirmationModal__Ap1Xc`,
	"cancelBtn": `DeleteConfirmationModal_cancelBtn__L89iV`,
	"okBtn": `DeleteConfirmationModal_okBtn__0BnYM`
};
export default ___CSS_LOADER_EXPORT___;
