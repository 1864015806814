import React, {FC} from 'react';
import {Button, Card, Col, Form, Input, message, Row, Space} from 'antd';

import styles from './Login.module.scss';
import {phoneMask} from "@typings/masks";
import {MaskedInput} from "antd-mask-input";
import {finishLogin} from "@components/Login/utils";
import {resetPassword, signUpSendSMS} from "@api";
import {showError} from "@utils/show_error";
import clsx from "clsx";

export interface LoginFormValues {
  username: string;
  password: string;
}

export const Login: FC = () => {
    const [form] = Form.useForm<LoginFormValues>()
    const [passwordSend, setPasswordSend] = React.useState(false)
    const onFinish = (values: LoginFormValues) => {
      finishLogin(values);
    };

    const onPasswordReset = () => {
      if (!form.getFieldValue('phone')) {
        form.setFields([
          {
            name: 'phone',
            errors: ['Введите логин!'],
          },
          {
            name: 'username',
            errors: ['Введите логин!'],
          },
        ])
        return
      }
      setPasswordSend(true)
      resetPassword({
        params: {
          UserName: (form.getFieldValue('phone') || '').replace(/\D/g, '')
        },
      }).then(
        (response) => {
          if (response.data.result === 0)
            message.success(response.data.error)
          else
            message.error(response.data.error)
        }
      ).catch(
        (error) => {
          if (error?.response?.data?.error)
            message.error(error?.response?.data?.error)
        }
      )
    }

    return (
      <Row justify='center' align='middle' style={{minHeight: '100vh'}} className={styles.loginBackground}>
        <Col>
          <div className={styles.loginFormContainer}>
            <Form onFinish={onFinish} className={styles.loginForm} layout={'vertical'} form={form}>
              <Form.Item name={'phone'} hidden noStyle/>
              <Space direction='vertical' size='small' className={styles.loginFormItems}>
                <Form.Item
                  label='Логин'
                  name='username'
                  rules={[{required: true, message: 'Укажите номер телефона'}]}
                >
                  <MaskedInput
                    onChange={(e) => form.setFieldValue('phone', e.currentTarget.value)}
                    mask={phoneMask}
                    placeholder={'+7 (___) ___ - __ - __'}
                  />
                </Form.Item>

                <Form.Item
                  label='Пароль'
                  name='password'
                  rules={[{required: true, message: 'Укажите пароль'}]}
                >
                  <Input.Password/>
                </Form.Item>

                  <Form.Item>
                    <button type='submit' className={styles.greenBtn}>
                      Войти
                    </button>
                  </Form.Item>
                <Button className={styles.registerBtn} type={'default'} href='/register'>Регистрация</Button>
                <Button className={styles.resetBtn} disabled={passwordSend} type={'text'}
                          onClick={onPasswordReset}>
                  Восстановить пароль
                </Button>
              </Space>
            </Form>
          </div>
        </Col>
      </Row>
    );
  }
;
