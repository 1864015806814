import { ServiceFetchType } from '@typings';
import { sendRequest } from '@api';
import { API_URI, jsonTypeRequestHeaders } from '@constants';
import {
  calculateOrderParams,
  calculateOrderResponse,
  cancelOrderParams,
  createOrderParams,
  createOrderResponse, fetchRequestDetailParams, fetchRequestDetailResponse,
  getDeliveryCompaniesParams,
  getDeliveryCompaniesResponse,
  getOrderLabelParams,
  getOrderLabelResponse,
  getOrdersListParams,
  getOrdersListResponse,
  getPVZParams,
  getPVZResponse,
} from '@typings/models/orders';
import {getPVZProvId} from "@typings/pages/createRequestTypes";

export const createOrder: ServiceFetchType<createOrderResponse, createOrderParams> = ({ params }) => {
  return sendRequest<createOrderResponse>(API_URI.CREATE_ORDER, {
    method: 'POST',
    data: params,
    headers: jsonTypeRequestHeaders,
  });
};

export const calculateOrder: ServiceFetchType<calculateOrderResponse, calculateOrderParams> = ({ params }) => {
  return sendRequest<calculateOrderResponse>(API_URI.CALCULATE_ORDER, {
    method: 'POST',
    data: params,
    headers: jsonTypeRequestHeaders,
  });
};

export const getDeliveryCompanies: ServiceFetchType<getDeliveryCompaniesResponse, getDeliveryCompaniesParams> = ({
  params,
}) => {
  return sendRequest<getDeliveryCompaniesResponse>(API_URI.GET_DELIVERY_COMPANIES, {
    method: 'POST',
    data: params,
    headers: jsonTypeRequestHeaders,
  });
};

export const getPVZList: ServiceFetchType<getPVZResponse, getPVZParams> = ({ params }) => {
  if (getPVZProvId.empty === params!.provID) {
    return sendRequest<getPVZResponse>(API_URI.GET_PVZ_ALL, {
      method: 'GET',
      params: {...params, provID: undefined},
      // headers: jsonTypeRequestHeaders,
    });
  }
  return sendRequest<getPVZResponse>(API_URI.GET_PVZ_LIST, {
    method: 'POST',
    data: params,
    headers: jsonTypeRequestHeaders,
  });
};

export const getOrdersList: ServiceFetchType<getOrdersListResponse, getOrdersListParams> = ({ params }) => {
  return sendRequest<getOrdersListResponse>(API_URI.GET_ORDERS_LIST, {
    method: 'POST',
    data: params,
    headers: jsonTypeRequestHeaders,
  });
};

export const getOrderLabel: ServiceFetchType<getOrderLabelResponse, getOrderLabelParams> = ({ params }) => {
  return sendRequest<getOrderLabelResponse>(API_URI.GET_ORDER_LABEL, {
    method: 'POST',
    data: params,
    headers: jsonTypeRequestHeaders,
  });
};

export const cancelOrder: ServiceFetchType<getOrderLabelResponse, cancelOrderParams> = ({ params }) => {
  return sendRequest<getOrderLabelResponse>(API_URI.CANCEL_PARCEL + '?Number=' + params!.number as API_URI, {
    method: 'POST',
    data: params,
    headers: jsonTypeRequestHeaders,
  });
};

export const fetchRequestDetail: ServiceFetchType<fetchRequestDetailResponse, fetchRequestDetailParams> = ({ params }) => {
  return sendRequest<fetchRequestDetailResponse>(API_URI.REQUEST_DETAIL, {
    method: 'POST',
    data: params,
    headers: jsonTypeRequestHeaders,
  });
};
