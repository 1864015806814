import {FC, useEffect, useState} from "react";
import {Select} from "antd";
import {getDeliveryCompanies} from "@api/services/orders";
import {DefaultOptionType, SelectProps} from "rc-select/lib/Select";
import {setDeliveryTypes} from "@store/reducers/deliveryTypes";
import {RootState, useAppDispatch, useAppSelector} from "@store";
import {removeDuplicates} from "@utils/removeDuplicates";
import {PVZ_CODE} from "@constants";

interface Props extends SelectProps {

}

export const DeliveryTypeSelect: FC<Props> = ({...props}) => {

  const [options, setOptions] = useState<DefaultOptionType[]>([]);
  const dispatch = useAppDispatch();
  const {data: deliveryTypesList} = useAppSelector((state: RootState) => state.deliveryTypes);

  const getOptions = () => {
    const marketPvz = Number(localStorage.getItem('marketPvz') || PVZ_CODE);
    getDeliveryCompanies({
      params: {marketPvz}
    }).then((response) => {
      if (response.data.list) dispatch(setDeliveryTypes({data: response.data.list}));
    })
  }

  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    if (deliveryTypesList) {
      setOptions(
        removeDuplicates(
          deliveryTypesList.map(item => ({
            label: item.providerName,
            value: item.providerName
          })),
          'value'
        )
      )
    }
  }, [deliveryTypesList]);

  return (
    <Select
      options={options}
      {...(props as any)}
    />
  )
}