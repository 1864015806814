import React from "react";
import {DownloadOutlined} from "@ant-design/icons";
import {Dropdown} from "antd";
import 'jspdf-autotable';
import {ColumnsType} from "antd/es/table";
import useExport from "@pages/RequestsPage/hooks/useExport";

interface Props<T> {
  tableData: T[];
  columns: ColumnsType<T>;
}

export const DownloadTableBtn = <T extends object>({columns, tableData}: Props<T>) => {
  const {
    onExcelPrint,
    onCsvPrint,
    onPdfPrint,
  } = useExport<T>({columns, data: tableData, fileName: 'Список отправлений'});
  const downloadTableDropdownOptions = [
    {
      key: 'csv',
      value: 'csv',
      label: (
        <span onClick={() => onDownloadTable('csv')}>
          CSV
        </span>
      )
    },
    {
      key: 'pdf',
      value: 'pdf',
      label: (
        <span onClick={() => onDownloadTable('pdf')}>
          PDF
        </span>
      )
    },
    {
      key: 'excel',
      value: 'excel',
      label: (
        <span onClick={onExcelPrint}>
          Excel
        </span>
      )
    }
  ];

  const onDownloadTable = (type: string) => {
    if (type === 'pdf') {
      onPdfPrint();
    }
    if (type === 'csv') {
      onCsvPrint();
    }
    if (type === 'excel') {
      onExcelPrint();
    }
  }

  return (
    <Dropdown trigger={['click']} placement={'bottom'} menu={{items: downloadTableDropdownOptions}}>
      <DownloadOutlined/>
    </Dropdown>
  )
}